// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DateTimePicker from 'react-datetime-picker';

// Custom Components
import Loader from '../Loader/Loader';

const EditModal = ({ show, handleInvoiceEdit, itemId, invoiceId }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    // Additional Information
    const [showAdditionalData, setShowAdditionalData] = useState(false);
    const HandleShowAdditionalData = (showOrHide) => {
        setShowAdditionalData(showOrHide)
    }

    // Handle Close
    const handleClose = (isUpdated = false) => {
        setShowModal(false);
        handleInvoiceEdit(itemId, invoiceId, false, 'after', isUpdated);
        setLoading(true);
    }

    const [formData, setFormData] = useState({
        price: 0,
        discount: 0,
        paid: 0,
        remain: 0,
        start_date: '',
        end_date: '',
        status: '',
        district: '',
        alley: '',
        house: '',
        company_name: '',
        company_activity: '',
        company_phone_number: '',
        errors: [],
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        if (name == "price") {
            let price_amount = parseInt(value);
            let discount_percentage = parseInt(formData.discount);
            let paid_amount = parseInt(formData.paid);
            let discount_amount = parseInt(price_amount * discount_percentage / 100);
            let remain_amount = ((price_amount) - (discount_amount + paid_amount));
            setFormData({ ...formData, remain: remain_amount, price: value });
        } else if (name == "paid") {
            let price_amount = parseInt(formData.price);
            let discount_percentage = parseInt(formData.discount);
            let paid_amount = parseInt(value);
            if (paid_amount <= price_amount) {
                let discount_amount = parseInt(price_amount * discount_percentage / 100);
                let remain_amount = ((price_amount) - (discount_amount + paid_amount));
                setFormData({ ...formData, remain: remain_amount, paid: value });
            }
        } else if (name == "discount") {
            let price_amount = parseInt(formData.price);
            let discount_percentage = parseInt(value);
            let paid_amount = parseInt(formData.paid);
            let discount_amount = parseInt(price_amount * discount_percentage / 100);
            let remain_amount = ((price_amount) - (discount_amount + paid_amount));
            setFormData({ ...formData, remain: remain_amount, discount: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let data = JSON.stringify({
            price: formData.price,
            discount: formData.discount,
            paid: formData.paid,
            remain: formData.remain,
            start_date: startDate,
            end_date: endDate,
            status: formData.status,
            district: formData.district,
            alley: formData.alley,
            house: formData.house,
            company_name: formData.company_name,
            company_activity: formData.company_activity,
            company_phone_number: formData.company_phone_number,
        });

        setIsSubmitting(true);

        axios.post(`invoices/update/${invoiceId}`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose(true);
                    setErrors([]);
                } else if (res.data.status === 400) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            axios.get(`invoices/edit/${invoiceId}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFormData({
                            price: res.data.invoice.price,
                            discount: res.data.invoice.discount,
                            paid: res.data.invoice.paid,
                            remain: res.data.invoice.remain,
                            status: res.data.invoice.status,
                            district: res.data.invoice.district,
                            alley: res.data.invoice.alley,
                            house: res.data.invoice.house,
                            company_name: res.data.invoice.company_name,
                            company_activity: res.data.invoice.company_activity,
                            company_phone_number: res.data.invoice.company_phone_number,
                            errors: [],
                        });
                        setLoading(false);
                        setStartDate(res.data.invoice.start_date);
                        setEndDate(res.data.invoice.end_date);
                    }
                })
        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-light-gray'>
                        <Modal.Title>{t('edit_invoice')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('price')}  * </Form.Label>
                                                <Form.Control type="number" name="price" onChange={handeInput} value={formData.price} />
                                                <span className='text-danger'>{errors.price}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('discount')}  * </Form.Label>
                                                <Form.Control type="number" name="discount" onChange={handeInput} value={formData.discount} />
                                                <span className='text-danger'>{errors.discount}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('paid')}  * </Form.Label>
                                                <Form.Control type="number" name="paid" onChange={handeInput} value={formData.paid} />
                                                <span className='text-danger'>{errors.paid}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('remain')}  * </Form.Label>
                                                <Form.Control type="number" name="remain" onChange={handeInput} value={formData.remain} readOnly />
                                                <span className='text-danger'>{errors.remain}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('start_date')}  * </Form.Label>
                                                <div dir="ltr">
                                                    <DateTimePicker className="form-control" name="start_date" onChange={setStartDate} value={startDate} format="y-MM-dd" required disableClock />
                                                    <span className='text-danger'>{errors.start_date}</span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('end_date')}  * </Form.Label>
                                                <div dir="ltr">
                                                    <DateTimePicker className="form-control" name="end_date" onChange={setEndDate} value={endDate} format="y-MM-dd" required disableClock />
                                                    <span className='text-danger'>{errors.end_date}</span>
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col className="col-lg-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="">---</option>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12}>
                                            <p className='bg-dark-gray py-2 px-3 rounded'>
                                                {t('additional_information')}
                                                {(() => {
                                                    if (showAdditionalData) {
                                                        return (
                                                            <FontAwesomeIcon icon="fa-solid fa-minus" className='mx-1 main-icon cursor-pointer' onClick={e => HandleShowAdditionalData(false)} />
                                                        );
                                                    } else {
                                                        return (
                                                            <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1 main-icon cursor-pointer' onClick={e => HandleShowAdditionalData(true)} />
                                                        );
                                                    }
                                                })()}
                                            </p>
                                        </Col>

                                        {(() => {
                                            if (showAdditionalData) {
                                                return (
                                                    <>
                                                        <Col lg={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('district')}</Form.Label>
                                                                <Form.Control type="text" name="district" onChange={handeInput} value={formData.district || ''} />
                                                                <span className='text-danger'>{errors.district}</span>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('alley')}</Form.Label>
                                                                <Form.Control type="text" name="alley" onChange={handeInput} value={formData.alley || ''} />
                                                                <span className='text-danger'>{errors.alley}</span>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('house')}</Form.Label>
                                                                <Form.Control type="text" name="house" onChange={handeInput} value={formData.house || ''} />
                                                                <span className='text-danger'>{errors.house}</span>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('company_name')}</Form.Label>
                                                                <Form.Control type="text" name="company_name" onChange={handeInput} value={formData.company_name || ''} />
                                                                <span className='text-danger'>{errors.company_name}</span>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('company_activity')}</Form.Label>
                                                                <Form.Control type="text" name="company_activity" onChange={handeInput} value={formData.company_activity || ''} />
                                                                <span className='text-danger'>{errors.company_activity}</span>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('company_phone_number')}</Form.Label>
                                                                <Form.Control type="text" name="company_phone_number" onChange={handeInput} value={formData.company_phone_number || ''} />
                                                                <span className='text-danger'>{errors.company_phone_number}</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                );
                                            }
                                        })()}

                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-light-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('close')}
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                            )}
                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default EditModal;