import { Route, Routes } from "react-router-dom";
import LoginMasterLayout from "../../components/admin/Auth/LoginMasterLayout";
import Login from "../../components/admin/Auth/Login";
import ControllLRoutes from "./ControllLRoutes";

const LRoues = () => {
    return (
        <Routes>
            <Route path="/" element={<LoginMasterLayout />}>
                <Route path="/" element={<ControllLRoutes />}>
                    <Route index element={<Login />} />
                    <Route path="login" element={<Login />} />
                </Route>
            </Route>
        </Routes >
    );
}

export default LRoues;