import './Login.css';

// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

// Other React Libraries
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Custom Components
import Loader from '../Loader/Loader';


const Login = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        errors: [],
    });

    const [errors, setErrors] = useState([]);

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            email: formData.email,
            password: formData.password
        });
        setIsSubmitting(true);
        axios.post(`login`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                    localStorage.setItem("auth_token", res.data.token);
                    localStorage.setItem("auth_name", res.data.username);
                    localStorage.setItem("user_id", res.data.user_id);
                    localStorage.setItem("user_pic", res.data.user_pic);
                    localStorage.setItem("building_id", res.data.building_id);
                    localStorage.setItem("role", res.data.role);
                    setTimeout(function () {
                        // if (res.data.role === "admin") {
                            navigate('/admin/dashboard');
                        // } else {
                        //     navigate('/');
                        // }
                    }, 3000);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                } else if (res.data.status === 401) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        document.title = t('login_title');
        setLoading(false);
    }, []);

    return (
        <>
            {(() => {
                if (loading) {
                    return <Loader />
                } else {
                    return (
                        <Container>
                            <Row className="row">
                                <Col  md={6} sm={12}  className="bg-purple d-flex justify-content-center align-items-center  form-col">
                                    <Form className='rouned-30 bg-white m-auto' onSubmit={handleFormSubmit} autoComplete="off" noValidate>

                                        <h1 className='text-center'>{t('login_title')}</h1>
                                        <p className='text-center mb-4 m-auto'>{t('login_detail')}</p>

                                        <Form.Group className="mb-4 position-relative">
                                            <Form.Label>{t('email')} * </Form.Label>
                                            <Form.Control type="email" name="email" onChange={handeInput} value={formData.email} placeholder="exmaple@you.com" />
                                            <span className='text-danger'>{errors.email}</span>
                                            <FontAwesomeIcon icon="fa-solid fa-user" className='mx-1 position-absolute input-icon' />
                                        </Form.Group>


                                        <Form.Group className="mb-4 position-relative">
                                            <Form.Label>{t('password')} * </Form.Label>
                                            <Form.Control type="password" name="password" onChange={handeInput} value={formData.password} placeholder="123456" />
                                            <span className='text-danger'>{errors.password}</span>
                                            <FontAwesomeIcon icon="fa-solid fa-key" className='mx-1 position-absolute input-icon' />
                                        </Form.Group>

                                        <Button variant="primary" type="submit" disabled={isSubmitting} className='bg-purple w-100 rouned-10 border-0'>
                                            {isSubmitting && (
                                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                            )}
                                            {t('login')}
                                        </Button>
                                    </Form>
                                </Col>
                                <Col md={6} sm={12} className="d-flex justify-content-center align-items-center image-col">
                                    <img src='/images/login.png' className='img-fluid' alt='profile' />
                                </Col>
                            </Row>
                        </Container>
                    )
                }
            })()}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </>
    );
}

export default Login;