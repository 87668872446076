// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ConfirmModal = ({ show, handleModal, apiUrl }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleClose = (isDone = false) => {
        setShowModal(false);
        handleModal(false, 'after', isDone);
    }

    const handleActionConfirmation = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        axios.post(`${apiUrl}`)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose(true);
                } else if (res.data.status === 401) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="md">
                <Modal.Header closeButton className='bg-dark-gray'>
                    <Modal.Title>{t('confirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white rounded p-3 mb-0'>
                    <Row>
                        <Col className="col-lg-12">
                            <p className='alert alert-info m-0'>{t('confirm_msg')}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='bg-dark-gray'>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('no')}
                    </Button>
                    <Button variant="primary" type="button" disabled={isSubmitting} onClick={handleActionConfirmation}>
                        {isSubmitting && (
                            <span className="spinner-grow spinner-grow-sm mr-2"></span>
                        )}
                        <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                        {t('yes')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ConfirmModal;