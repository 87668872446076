// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Custom Components
import Loader from '../Loader/Loader';

const AddModal = ({ show, handleOfficeAdd, floor, building, pos }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleClose = (isCreated=false) => {
        setShowModal(false);
        handleOfficeAdd(pos, false, 'after', isCreated);
    }

    const [formData, setFormData] = useState({
        name: '',
        status: 1,
        note: '',
        room_size: '',
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const resetFormData = () => {
        setFormData({
            name: '',
            status: '',
            note: '',
            room_size: '',
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            name: formData.name,
            status: formData.status,
            building: building,
            floor: floor,
            note: formData.note,
            room_size: formData.room_size,
            pos: pos,
        });
      
        setIsSubmitting(true);
        axios.post(`offices/store`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    resetFormData();
                    handleClose(true);
                    setErrors([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(false);
        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('add_office')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col className="col-lg-4">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('name')} * </Form.Label>
                                                <Form.Control type="text" name="name" onChange={handeInput} value={formData.name} />
                                                <span className='text-danger'>{errors.name}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-4">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="">---</option>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>
                                        
                                        <Col className="col-lg-4">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('room_size')}: </Form.Label>
                                                <Form.Control type="text" name="room_size" onChange={handeInput} value={formData.room_size} />
                                                <span className='text-danger'>{errors.room_size}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('note')}: </Form.Label>
                                                <Form.Control as="textarea" rows={3} name="note" onChange={handeInput} value={formData.note} />
                                                <span className='text-danger'>{errors.note}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                            )}
                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddModal;