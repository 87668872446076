import './ListReport.css';

// React Core components
import React, { useState, useEffect } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, Link } from 'react-router-dom';

// Custom Components
import Breadcrumb from "../../../../layouts/Admin/Breadcrumb";
import AddModal from "../AddModal";
import EditModal from "../EditModal";
import Loader from "../../Loader/Loader";
import ListUserInvoice from './ListInvoice';

const ListReport = () => {
    const { t } = useTranslation();
    const { id, status } = useParams();

    const [loading, setLoading] = useState(true);
    const [userDetail, setUserDetail] = useState([]);

    const loadData = () => {
        axios.get(`users/details/${id}`)
            .then(res => {
                if (res.data.status === 200) {
                    setUserDetail(res.data.user);
                };
                setLoading(false);
            });
    }

    useEffect(() => {
        loadData();
    }, [status]);

    let userInfoHTML = '';
    if (loading) {
        return <Loader />
    } else {
        userInfoHTML =
            <div>
                <div className='m-auto profile-pic'>
                    <img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/${userDetail.pic}`} className='rounded-circle' width="126" height="126" alt={userDetail.name} />
                </div>
                <h1 className='text-center profile-name my-2'>{userDetail.name}</h1>
                <ul className='mt-4 list-group list-group-flush profile-info p-0'>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("id_card")}</span>
                        <span>{userDetail.id_card}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("name")}</span>
                        <span>{userDetail.name}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("email")}</span>
                        <span>{userDetail.email}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("phone")}</span>
                        <span>{userDetail.phone}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("company")}</span>
                        <span>{userDetail.company}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("address")}</span>
                        <span>{userDetail.address}</span>
                    </li>
                </ul>
            </div>
    }

    return (
        <>
            <Breadcrumb title={t('reports')} />
            <Container className="pr-30 pl-30 mb-5 user-profile-reports" id="main-container">
                <Row>
                    <Col lg={3}>
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                            {userInfoHTML}
                        </div>
                    </Col>
                    <Col lg={9}>

                        <Row>
                            <Col lg={6}>
                                <div className='pt-3 pl-15 pr-15 pb-15 pt-15 rounded green-box'>
                                    <p className='mb-2 d-flex justify-content-between align-items-center'>
                                        <span>{t('invoice_total')}</span>
                                        <span>9000</span>
                                    </p>
                                    <p className='mb-2 d-flex justify-content-between align-items-center'>
                                        <span>{t('charges')}</span>
                                        <span>9000</span>
                                    </p>
                                    <p className='mb-0 d-flex justify-content-between align-items-center'>
                                        <span>
                                            <FontAwesomeIcon icon="fa-solid fa-file-invoice-dollar" className='mx-1' />
                                            {t('total')}
                                        </span>
                                        <span>9000</span>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='pt-3 pl-15 pr-15 pb-15 pt-15 rounded red-box'>
                                    <p className='mb-2 d-flex justify-content-between align-items-center'>
                                        <span>{t('final_invoices_total')}</span>
                                        <span>9000</span>
                                    </p>
                                    <p className='mb-2 d-flex justify-content-between align-items-center'>
                                        <span>{t('charges')}</span>
                                        <span>9000</span>
                                    </p>
                                    <p className='mb-0 d-flex justify-content-between align-items-center'>
                                        <span>
                                            <FontAwesomeIcon icon="fa-solid fa-file-invoice-dollar" className='mx-1' />
                                            {t('total_charges')}
                                        </span>
                                        <span>9000</span>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mt-3 pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                                    <ListUserInvoice id={id} status={status}/>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ListReport;