// React Core components
import { useState } from 'react';

// React-Bootstrap components
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const QuickAdd = ({ show, handleHideShow, handleNewAddedItem }) => {

    const { t } = useTranslation();

    const [isAddAccountTypeFormSubmitting, setIsAddAccountTypeFormSubmitting] = useState(false);
    const [accountTypeErrors, setAccountTypeErrors] = useState([]);
    const [accountTypeAddFormData, setAccountTypeAddFormData] = useState({
        account_type: '',
        errors: [],
    });


    const hideForm = (type) => {
        handleHideShow(type);
    }

    const newAddedItem = (item) => {
        handleNewAddedItem(item);
    }

    const handleAddAccountTypeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setAccountTypeAddFormData({ ...accountTypeAddFormData, [name]: value });
    }

    const saveAddAccountTypeFormData = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            account_type: accountTypeAddFormData.account_type,
        });

        setIsAddAccountTypeFormSubmitting(true);

        axios.post(`account-types/store`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setAccountTypeErrors([]);
                    handleHideShow(false);
                    setAccountTypeAddFormData({
                        account_type: '',
                        errors: [],
                    });

                    newAddedItem(res.data.account_type);

                } else if (res.data.status === 422) {
                    setAccountTypeErrors(res.data.errors);
                }
                setIsAddAccountTypeFormSubmitting(false);
            });
    }

    return (
        <>
            {(() => {
                if (show) {
                    return (
                        <div>
                            <div className='dark-layer'></div>
                            <div className="position-absolute w-100 bg-white rounded px-3 py-4 border-1 float-modal">
                                <Row>
                                    <Col lg={9}>
                                        <Form.Group className="mb-2">
                                            <Form.Label>{t('account_type')}  * </Form.Label>
                                            <Form.Control type="text" name="account_type" onChange={handleAddAccountTypeInput} value={accountTypeAddFormData.account_type} />
                                        </Form.Group>
                                        <span className='text-danger'>{accountTypeErrors.account_type}</span>
                                    </Col>
                                    <Col lg={3}>
                                        <Button variant="primary" type="button" disabled={isAddAccountTypeFormSubmitting} onClick={saveAddAccountTypeFormData}>
                                            {isAddAccountTypeFormSubmitting && (
                                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                            )}
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                            {t('save')}
                                        </Button>
                                        <Button variant="danger" type="button" className='mx-1' onClick={() => hideForm(false)}>
                                            <FontAwesomeIcon icon="fa-solid fa-times" className='mx-1' />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
                }
            })()}
        </>
    )
}

export default QuickAdd;