// React Core components
import React, { useState, useEffect } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import Pagination from 'react-bootstrap/Pagination';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Link, NavLink } from "react-router-dom";

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import Loader from "../Loader/Loader";

const ListInvoice = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [itemList, setItemList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const [totalPages, setTotalPages] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    const [filtrationData, setFiltrationData] = useState({
        per_page: 20,
        number: '',
        status: 'all',
        page: 1,
    });

    const handeSearchInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFiltrationData({ ...filtrationData, [name]: value, page: 1 });
    }

    const handeShowPerPage = (e) => {
        setFiltrationData({ ...filtrationData, per_page: e.target.value, page: 1 });
    }

    const handlePagination = (e) => {
        setFiltrationData({ ...filtrationData, page: e.target.value });
    }

    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        loadData();
    }

    const loadData = () => {
        setTableLoading(true);
        let data = filtrationData;
        axios.get(`invoices?page=${data.page}&per_page=${data.per_page}&number=${data.number}&status=${data.status}&building_id=` + localStorage.getItem('building_id'))
            .then(res => {
                if (res.data.status === 200) {
                    setItemList(res.data.invoices.data);
                    setLoading(false);
                    setTableLoading(false);

                    var pagesCounter = [];
                    for (var i = 0; i < res.data.total_pages; i++) {
                        pagesCounter.push({ i });
                    }
                    setTotalPages(pagesCounter);
                    setTotalRecords(res.data.total_records);
                };
            });
    }

    useEffect(() => {
        loadData();
    }, [filtrationData.page, filtrationData.per_page]);

    let dataTableData = '';
    let tablePagination = '';
    if (loading) {
        return <Loader />
    } else {
        if (itemList.length > 0) {
            dataTableData =
                itemList.map((item, index) => {
                    return (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.user_name}</td>
                            <td>{item.client_first_name + ' ' + item.client_last_name}</td>
                            <td>{item.price}</td>
                            <td>{item.discount}</td>
                            <td>{item.paid}</td>
                            <td>{item.remain}</td>
                            <td>{item.start_date}</td>
                            <td>{item.end_date}</td>
                            <td>{item.building_name}</td>
                            <td>{item.floor_name}</td>
                            <td>{item.office_name}</td>
                            <td>
                                {(() => {
                                    if (item.status === 1) {
                                        return <Badge bg="success">{t('active')}</Badge>
                                    } else {
                                        return <Badge bg="danger">{t('inactive')}</Badge>
                                    }
                                })()}
                            </td>
                            <td>
                                <NavLink target="_blank" as={Link} to={`/admin/invoices/view/${item.id}`} className='d-block w-100 py-3 px-3 text-decoration-none'>
                                    <FontAwesomeIcon icon="fa-solid fa-eye" className='mx-1' />
                                </NavLink>
                            </td>
                        </tr>
                    );
                });

            tablePagination =
                totalPages.map((item, i) => {
                    return (
                        <option key={i} value={i + 1}>{i + 1}</option>
                    )
                });

        } else {
            dataTableData = (
                <tr>
                    <td className="text-center" colSpan="30">{t('no_records_found')}</td>
                </tr>
            );
        }
    }

    return (
        <>
            <Breadcrumb title={t('invoices')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">

                            <Form className='bg-light-gray rounded mb-3 p-3' onSubmit={handleSearchFormSubmit} autoComplete="off">
                                <Row>
                                    <Col className="col-lg-4">
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('name')}: </Form.Label>
                                            <Form.Control type="text" name="name" onChange={handeSearchInput} value={filtrationData.name ?? ''} />
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-lg-4">
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('status')}: </Form.Label>
                                            <Form.Select name="status" onChange={handeSearchInput} value={filtrationData.status} >
                                                <option value="all">{t('all')}</option>
                                                <option value="1">{t('active')}</option>
                                                <option value="0">{t('inactive')}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-lg-4">
                                        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                            <Button variant="primary" className='mt-4' type="submit">
                                                <FontAwesomeIcon icon="fa-solid fa-search" className='mx-1' />
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>

                            <div className='datatable-action-area mb-3 d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <Form.Label className='text-nowrap'>{t('show_per_page')}</Form.Label>
                                    <Form.Select className='mx-2' name="show_per_page" onChange={handeShowPerPage}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Form.Select>
                                </div>
                            </div>

                            <div className="datatable-action-area mb-3 d-flex justify-content-between flex-column">
                                {(() => {
                                    if (tableLoading) {
                                        return <Loader />
                                    } else {
                                        return (
                                            <Table className="m-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{t('user')}</th>
                                                        <th>{t('client')}</th>
                                                        <th>{t('price')}</th>
                                                        <th>{t('discount')}</th>
                                                        <th>{t('paid')}</th>
                                                        <th>{t('remain')}</th>
                                                        <th>{t('start_date')}</th>
                                                        <th>{t('end_date')}</th>
                                                        <th>{t('building')}</th>
                                                        <th>{t('floor')}</th>
                                                        <th>{t('office')}</th>
                                                        <th>{t('status')}</th>
                                                        <th>{t('view')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataTableData}
                                                </tbody>
                                            </Table>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (totalRecords > filtrationData.per_page) {
                                        return (
                                            <Form.Group className="m-0 d-flex align-items-center mt-3 pagination-dropdown">
                                                <Form.Label className="mx-2 my-0">{t('page')}: </Form.Label>
                                                <Form.Select name="page" onChange={handlePagination} value={filtrationData.page} >
                                                    {tablePagination}
                                                </Form.Select>
                                            </Form.Group>
                                        );
                                    }
                                })()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ListInvoice;