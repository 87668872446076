// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const QuickAdd = ({ show, handleHideShow, handleNewAddedItem, additionalParams = {} }) => {

    const { t } = useTranslation();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        account_type: '',
        building: '',
        status: '',
        errors: [],
    });


    const hideForm = (type) => {
        handleHideShow(type);
    }

    const newAddedItem = (item) => {
        handleNewAddedItem(item);
    }

    const handleInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            name: formData.name,
            account_type: formData.account_type,
            building: formData.building,
            status: 1,
        });

        setIsSubmitted(true);

        axios.post(`accounts/store`, data)
            .then(res => { //console.log(res.data);
                if (res.data.status === 200) { 
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                    handleHideShow(false);
                    setFormData({
                        account_type: '',
                        building: '',
                        status: '',
                        errors: [],
                    });

                    newAddedItem(res.data.account);

                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitted(false);
            });
    }

    useEffect(() => {
        setFormData({
            account_type: additionalParams.account_type,
            building: additionalParams.building,
            status: 1,
            errors: [],
        });
    }, [show, additionalParams]);

    return (
        <>
            {(() => {
                if (show) {
                    return (
                        <div>
                            <div className='dark-layer'></div>
                            <div className="position-absolute w-100 bg-white rounded px-3 py-4 border-1 float-modal">
                                <Row>
                                    <Col lg={9}>
                                        <Form.Group className="mb-2">
                                            <Form.Label>{t('name')}  * </Form.Label>
                                            <Form.Control type="text" name="name" onChange={handleInput} value={formData.name || ''} autoComplete='off' />
                                        </Form.Group>
                                        <p className='text-danger m-0'>{errors.name}</p>
                                        <p className='text-danger m-0'>{errors.building}</p>
                                    </Col>
                                    <Col lg={3}>
                                        <Button variant="primary" type="button" disabled={isSubmitted} onClick={handleFormSubmit}>
                                            {isSubmitted && (
                                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                            )}
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                            {t('save')}
                                        </Button>
                                        <Button variant="danger" type="button" className='mx-1' onClick={() => hideForm(false)}>
                                            <FontAwesomeIcon icon="fa-solid fa-times" className='mx-1' />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
                }
            })()}
        </>
    )
}

export default QuickAdd;