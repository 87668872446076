// React
import { useEffect, useState } from "react";
// Other
import axios from "axios";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

// Custom 
import AdminMasterLayout from "../../layouts/Admin/MasterLayout";
import ControllARoutes from "./ControllARoutes";
import ListDashboard from "../../components/admin/Dashboard/ListDashboard";
import ListUser from "../../components/admin/User/ListUser";
import ListBuilding from "../../components/admin/Building/ListBuilding";
import ListFloor from "../../components/admin/Floor/ListFloor";
import ListOffice from "../../components/admin/Office/ListOffice";
import AddEditOffice from "../../components/admin/Office/AddEditOffice";
import ListInvoice from "../../components/admin/Invoice/ListInvoice";
import AddEditInvoice from "../../components/admin/Invoice/AddEditInvoice";
import ListSettings from "../../components/admin/Settings/ListSettings";
import ListProfile from "../../components/admin/Profile/ListProfile";
import ListActivity from "../../components/admin/Activity/ListActivity";
import Listmap from "../../components/admin/Map/Listmap";
import Viewmap from "../../components/admin/Map/Viewmap";
import UserReport from "../../components/admin/User/Report/ListReport";
import OfficeReport from "../../components/admin/Office/Report/ListReport";
import Loader from "../../components/admin/Loader/Loader";
import ListAccount from "../../components/admin/Account/ListAccount";
import ListBoxActivity from "../../components/admin/BoxActivity/ListBoxActivity";
import BoxActivityInvoice from "../../components/admin/BoxActivity/Invoice";
import ViewInvoice from "../../components/admin/Invoice/ViewInvoice";
import ListClient from "../../components/admin/Client/ListClient";
import ListBoxActivityByAccount from "../../components/admin/Account/ListBoxActivity";

import ListMultiBoxActivity from "../../components/admin/MultiBoxActivity/ListMultiBoxActivity";
import MultiBoxActivityInvoice from "../../components/admin/MultiBoxActivity/Invoice";


const ARoues = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    axios.interceptors.response.use(undefined, (err) => {
        if (err.response.status === 401) {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("auth_name");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_pic");
            window.location.reload();
        }
    });

    if (loading) {
        return <Loader />
    }

    return (
        <Routes>
            <Route path="/" element={<AdminMasterLayout />}>
                <Route path="/" element={<ControllARoutes />}>
                    <Route index element={<ListDashboard />} />
                    <Route path="dashboard" element={<ListDashboard />} />

                    <Route path="users" element={<ListUser />} />

                    <Route path="clients" element={<ListClient />} />

                    <Route path="accounts" element={<ListAccount />} />
                    <Route path="accounts/box-activities/:accountID" element={<ListBoxActivityByAccount />} />

                    <Route path="box-activities" element={<ListBoxActivity />} />
                    <Route path="box-activities/invoice/:id" element={<BoxActivityInvoice />} />

                    <Route path="multi-box-activities" element={<ListMultiBoxActivity />} />
                    <Route path="multi-box-activities/invoice/:id" element={<MultiBoxActivityInvoice />} />

                    <Route path="users/reports/:id/:status?" element={<UserReport />} />

                    <Route path="buildings" element={<ListBuilding />} />

                    <Route path="/floors/:building" element={<ListFloor />} />

                    <Route path="/offices/:building/:floor" element={<ListOffice />} />
                    <Route path="offices/add/edit/:building/:floor" element={<AddEditOffice />} />
                    <Route path="offices/reports/:id/:type" element={<OfficeReport />} />

                    <Route path="manage-invoices/add/edit" element={<AddEditInvoice />} />


                    <Route path="invoices" element={<ListInvoice />} />
                    <Route path="invoices/add/edit" element={<AddEditInvoice />} />

                    <Route path="activities" element={<ListActivity />} />


                    <Route path="invoices/view/:id" element={<ViewInvoice />} />

                    <Route path="maps" element={<Listmap />} />
                    <Route path="/maps/:url" element={<Viewmap />} />

                    <Route path="settings" element={<ListSettings />} />

                    <Route path="profile" element={<ListProfile />} />
                </Route>
            </Route>
        </Routes >
    );
}

export default ARoues;