import Spinner from 'react-bootstrap/Spinner';
const Loader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center mt-4">
            <Spinner animation="border" role="status" className='circle-loader-border'>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Loader;