// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AccountDropdown from '../SearchableDropdown/SelectDropdown';

// Custom Components
import Loader from '../Loader/Loader';

const EditModal = ({ show, handleEdit, itemId }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    // From And To Balances
    const [fromAccountBalanceLoader, setFromAccountBalanceLoader] = useState(false);
    const [toAccountBalanceLoader, setToAccountBalanceLoader] = useState(false);

    // From Account Dropdown
    const [fromAccount, setFromAccount] = useState('');
    const handleFromAccountSelect = (value) => {
        setFromAccount(value);
        setFromAccountBalanceLoader(true);
        axios.get(`accounts/balance/${value.id}`)
            .then(res => {
                if (res.data.status === 200) {
                    setFormData({ ...formData, from_balance: res.data.balance });
                    setFromAccountBalanceLoader(false);
                }
            });
    }

    // To Account Dropdown
    const [toAccount, setToAccount] = useState('');
    const handleToAccountSelect = (value) => {
        setToAccount(value);
        setToAccountBalanceLoader(true);
        axios.get(`accounts/balance/${value.id}`)
            .then(res => {
                if (res.data.status === 200) {
                    setFormData({ ...formData, to_balance: res.data.balance });
                    setToAccountBalanceLoader(false);
                }
            });
    }

    // Handle Close Modal
    const handleClose = (isUpdated = false) => {
        setShowModal(false);
        handleEdit(null, false, 'after', isUpdated);
    }

    // Form Data
    const [formData, setFormData] = useState({
        from_balance: 0,
        to_balance: 0,
        exchange_rate: 1,
        usd_amount: 0,
        dinar_amount: 0,
        current_from_account: "",
        current_to_account: "",
        note: '',
        errors: [],
    });

    // Hande Input
    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    // Handle Submit
    const handleFormSubmit = (e) => {
        e.preventDefault();

        let data = JSON.stringify({
            new_from_account: fromAccount.id,
            new_to_account: toAccount.id,
            exchange_rate: formData.exchange_rate,
            usd_amount: formData.usd_amount,
            dinar_amount: formData.dinar_amount,
            current_from_account: formData.current_from_account,
            current_to_account: formData.current_to_account,
            note: formData.note,
        });
        setIsSubmitting(true);
        axios.post(`box-activities/update/${itemId}`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose(true);
                    setErrors([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                } else if (res.data.status === 400) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                }
                setIsSubmitting(false);
            });
    }

    // Load Data
    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(true);
            axios.get(`box-activities/edit/${itemId}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFormData({
                            from_balance: (res.data.from_account.total_debits - res.data.from_account.total_credits),
                            to_balance: (res.data.to_account.total_debits - res.data.to_account.total_credits),
                            exchange_rate: res.data.to_account.exchange_rate,
                            usd_amount: (res.data.to_account.iq_debit / res.data.to_account.exchange_rate),
                            dinar_amount: 0,
                            note: res.data.to_account.description,
                            current_from_account: res.data.from_account.account_id,
                            current_to_account: res.data.to_account.account_id,
                            errors: [],
                        });
                        setFromAccount({ id: res.data.from_account.account_id, name: res.data.from_account.account_name });
                        setToAccount({ id: res.data.to_account.account_id, name: res.data.to_account.account_name });
                        setLoading(false);
                    }
                })
        }
    }, [show, itemId]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('edit_box_activity')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label>
                                                <span>{t('from_account')} *</span>
                                            </Form.Label>
                                            <AccountDropdown apiUrl={`accounts-autocomplete`} handleSelect={handleFromAccountSelect} newAddedItem={fromAccount} />
                                            <span className='text-danger'>{errors.new_from_account}</span>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3 position-relative">
                                                <Form.Label>{t('balance')}  * </Form.Label>
                                                <Form.Control type="number" readOnly name="from_balance" onChange={handeInput} value={formData.from_balance} />
                                                {fromAccountBalanceLoader && (
                                                    <Spinner animation="border" className='position-absolute bg-dark-gray dropdown-loader' />
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Label>
                                                <span>{t('to_account')} *</span>
                                            </Form.Label>
                                            <AccountDropdown apiUrl={`accounts-autocomplete`} handleSelect={handleToAccountSelect} newAddedItem={toAccount} />
                                            <span className='text-danger'>{errors.new_to_account}</span>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3 position-relative">
                                                <Form.Label>{t('balance')}  * </Form.Label>
                                                <Form.Control readOnly type="number" name="to_balance" onChange={handeInput} value={formData.to_balance} />
                                                {toAccountBalanceLoader && (
                                                    <Spinner animation="border" className='position-absolute bg-dark-gray dropdown-loader' />
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('exchange_rate')}  * </Form.Label>
                                                <Form.Control type="number" name="exchange_rate" onChange={handeInput} value={formData.exchange_rate} />
                                                <span className='text-danger'>{errors.exchange_rate}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('amount')} ({t('usd')}) * </Form.Label>
                                                <Form.Control type="text" name="usd_amount" onChange={handeInput} value={formData.usd_amount} />
                                                <span className='text-danger'>{errors.usd_amount}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('note')}</Form.Label>
                                                <Form.Control as="textarea" rows={3} name="note" onChange={handeInput} value={formData.note ?? ''} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {(() => {
                            if (!loading) {
                                return (
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && (
                                            <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                        )}
                                        <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                        {t('save')}
                                    </Button>
                                )
                            }
                        })()}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );

}

export default EditModal;