// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';

// Other React Libraries
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import Loader from '../Loader/Loader';

import Map1 from '../Map/Map1';
import Map2 from '../Map/Map2';
import Map3 from '../Map/Map3';
import Map4 from '../Map/Map4';
import Map5 from '../Map/Map5';
import Map6 from '../Map/Map6';

const AddEditOffice = () => {
    const { t } = useTranslation();
    const { building, floor } = useParams();

    const [loading, setLoading] = useState(true);
    const [buildings, setBuildings] = useState([]);
    const [floorLoader, setFloorLoader] = useState(false);
    const [floors, setFloors] = useState([]);

    const [map, setMap] = useState(null);
    const [maploader, setMaploader] = useState(false);

    const [selectedBuilding, setSelectedBuilding] = useState(0);
    const [selectedFloor, setSelectedFloor] = useState(0);

    /*
    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;

        if (name == "building") {
            setFloorLoader(true);
            axios.get(`floors/bybuilding/${value}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFloors(res.data.floors);
                        setFloorLoader(false);
                        setSelectedBuilding(value);
                    }
                });
        }

        if (name == "floor") {
            setMaploader(true);
            axios.get(`floors/byid/${value}`)
                .then(res => {
                    if (res.data.status === 200) {
                        const targetMap = res.data.map;
                        setSelectedFloor(value)
                        if (targetMap == "map1") {
                            setMap(<Map1 map={targetMap} floor={value} building={selectedBuilding} page="office" />);
                        } else {
                            setMap(<p className="alert alert-info text-center"> {t('no_map_found')}</p>);
                        }
                        setMaploader(false);
                    }
                });
        }
    }
    */

    const loadMap = () => {
        setMaploader(true);
        axios.get(`floors/byid/${floor}`)
            .then(res => {
                if (res.data.status === 200) {
                    const targetMap = res.data.map;
                    setSelectedFloor(floor)
                    if (targetMap == "romadi1") {
                        setMap(<Map1 map={targetMap} floor={floor} building={building} page="office" />);
                    } else if (targetMap == "romadi2") {
                        setMap(<Map2 map={targetMap} floor={floor} building={building} page="office" />);
                    } else if (targetMap == "karad1") {
                        setMap(<Map3 map={targetMap} floor={floor} building={building} page="office" />);
                    } else if (targetMap == "karad2") {
                        setMap(<Map4 map={targetMap} floor={floor} building={building} page="office" />);
                    } else if (targetMap == "basrah") {
                        setMap(<Map5 map={targetMap} floor={floor} building={building} page="office" />);
                    } else if (targetMap == "abujafar") {
                        setMap(<Map6 map={targetMap} floor={floor} building={building} page="office" />);
                    } else {
                        setMap(<p className="alert alert-info text-center"> {t('no_map_found')}</p>);
                    }
                    setMaploader(false);
                }
            });
    }


    useEffect(() => {
        setLoading(true);
        loadMap();
        setLoading(false);
        // axios.get(`buildings/all`)
        //     .then(res => {
        //         if (res.data.status === 200) {
        //             setBuildings(res.data.buildings);
        //             setLoading(false);
        //         }
        //     })
    }, []);

    return (
        <>
            <Breadcrumb title={t('manage_offices')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        <div className="py-3 mb-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white">
                            <div className='alert alert-info m-0'>
                                <p className='m-0'>- {t('office_management_not3')}</p>
                                <p className='m-0'>- {t('office_management_not4')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-md-12">
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                            {(() => {
                                if (loading) {
                                    return <Loader />
                                } else {
                                    return (
                                        <Row>
                                            {/* <Col className="col-lg-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('building')} * </Form.Label>
                                                    <Form.Select name="building" onChange={handeInput} value={selectedBuilding}>
                                                        <option value=''>---</option>
                                                        {
                                                            buildings.map((item) => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col> */}
                                            {/* <Col className="col-lg-6">
                                                <Form.Group className="mb-3 position-relative">
                                                    {(() => {
                                                        if (floorLoader) {
                                                            return <Spinner animation="border" className='position-absolute bg-white dropdown-loader' />
                                                        }
                                                    })()}
                                                    <Form.Label>{t('floor')} * </Form.Label>
                                                    <Form.Select name="floor" onChange={handeInput} value={selectedFloor}>
                                                        <option value="">---</option>
                                                        {(() => {
                                                            if (floors.length > 0) {
                                                                return (
                                                                    floors.map((item) => (
                                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                                    ))
                                                                )
                                                            }
                                                        })()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col> */}
                                            <Col lg={12}>
                                                <Button variant="primary" className='mt-4 btn-black position-relative' as={Link} to={`/admin/offices/${building}/${floor}`}>
                                                    {t('back')}
                                                    <FontAwesomeIcon icon="fa-solid fa-angle-left" className='mx-1' />
                                                </Button>
                                            </Col>
                                            <Col lg={12}>
                                                {(() => {
                                                    if (maploader) {
                                                        return <Loader />
                                                    } else {
                                                        return map
                                                    }
                                                })()}
                                            </Col>
                                        </Row>
                                    )
                                }
                            })()}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AddEditOffice;