import { useState, useEffect } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

const SelectDropdown = ({ apiUrl, handleSelect, newAddedItem, additionalParams = {} }) => {

    const [KeyWord, setKeyWord] = useState('');
    const [selectedRecord, setSelectedRecord] = useState('');

    // Handle Change Event
    const handleRecordChange = value => {
        setKeyWord(value);
    };

    // Handle Record Selection
    const handleRecordSelection = value => {
        setSelectedRecord(value);
        handleSelect(value);
    }

    // Load Records From server
    const loadRecords = async () => {
        const result = await axios.get(`${apiUrl}`, {
            params: {
                key_word: KeyWord,
                additionalParams,
                building_id: localStorage.getItem('building_id')
            }
        });
        const res = result.data.records;
        return res;
    }


    useEffect(() => {
        if (typeof newAddedItem === 'object' && !Array.isArray(newAddedItem) && newAddedItem !== null) {
            setSelectedRecord(newAddedItem);
        }
    }, [newAddedItem]);


    return (
        <AsyncSelect
            isClearable
            cacheOptions
            defaultOptions
            placeholder=""
            value={selectedRecord}
            getOptionLabel={e => e.name}
            getOptionValue={e => e.id}
            loadOptions={loadRecords}
            onInputChange={handleRecordChange}
            onChange={handleRecordSelection}
        />
    );
}
export default SelectDropdown;