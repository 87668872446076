// React Core components
import React, { useState, useEffect } from "react";

// Other React Libraries
import axios from "axios";

// Custom Components
import Loader from "../Loader/Loader";
import OfficeAddModal from "../Office/AddModal";
import OfficeEditModal from "../Office/EditModal";
import InvoiceAddModal from "../Invoice/AddModal";
import InvoiceEditModal from "../Invoice/EditModal";

import './Styles2.css';
import { useNavigate } from "react-router-dom";

const Map2 = ({ map, floor, building, page }) => {
    const [offices, setOffices] = useState([]);
    const [maploader, setMaploader] = useState(false);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [pos, setPos] = useState(-1);

    const [itemId, setItemId] = useState(null);
    const [invoiceId, setInvoiceId] = useState(null);

    const navigate = useNavigate();

    const handleOfficeAdd = (pos, show, type = "before", isCreated = false) => {
        setShowAddModal(show);
        setPos(pos);
        if (type == "after" && isCreated == true) {
            loadMap();
        }
    }

    const handleOfficeEdit = (id, show, type = "before", isUpdated = false) => {
        setShowEditModal(show);
        setItemId(id);
        if (type == "after" && isUpdated == true) {
            loadMap();
        }
    }

    const handleInvoiceAdd = (office_id, show, type = "before", isCreated = false) => {
        setShowAddModal(show);
        setItemId(office_id);
        if (type == "after" && isCreated == true) {
            loadMap();
        }
    }

    const handleInvoiceEdit = (office_id, invoice_id, show, type = "before", isUpdated = false, officeStatus = '') => {
        if (officeStatus === 0) {
            setShowEditModal(show);
            setItemId(office_id);
            setInvoiceId(invoice_id);
            if (type == "after" && isUpdated == true) {
                loadMap();
            }
        } else {
            navigate(`/admin/offices/reports/${office_id}/invoices`);
        }
    }

    const loadMap = () => {
        setMaploader(true);
        axios.get(`offices/bymap/${map}/${floor}`)
            .then(res => {
                if (res.data.status === 200) {
                    setOffices(res.data.offices);
                    setMaploader(false);
                }
            });
    }

    useEffect(() => {
        loadMap();
    }, []);

    let dataTableData = '';
    if (maploader) {
        return <Loader />
    } else {
        dataTableData =
            <div style={{ width: "600px" }} className="m-auto position-relative">
                <img src="/maps/romadi/2.png" alt="Romadi floor 2" className="w-100" />
                {
                    offices.map((office, idx) => {
                        return (
                            (page === "office")
                                ? (
                                    (office !== null && office !== undefined)
                                        ? (<span id={`p${idx}`} onClick={() => handleOfficeEdit(office.id, true)} key={idx}><p>{office.name}</p></span>)
                                        : (<span id={`p${idx}`} onClick={() => handleOfficeAdd(idx, true)} key={idx}></span>)
                                )
                                : (
                                    (office !== null && office !== undefined)
                                        ? (office.office_status === 1 || office.office_status === 2)
                                            ? (<span style={{ backgroundColor: office.status_color }} id={`p${idx}`} onClick={() => handleInvoiceEdit(office.id, office.invoice_id, true, 'before', false, office.office_status)} key={idx}><p>{office.name}</p></span>)
                                            : (<span style={{ backgroundColor: office.status_color }} id={`p${idx}`} onClick={() => handleInvoiceAdd(office.id, true)} key={idx}><p>{office.name}</p></span>)
                                        : (<span id={`p${idx}`} key={idx}></span>)
                                )
                        )
                    })
                }
            </div>
    }
    return (
        <div className="datatable-action-area mb-3 table-map table-map2">
            {dataTableData}
            {
                (page !== null && page !== undefined && page == "office")
                    ? (<OfficeAddModal show={showAddModal} handleOfficeAdd={handleOfficeAdd} floor={floor} building={building} pos={pos} />)
                    : (<InvoiceAddModal show={showAddModal} handleInvoiceAdd={handleInvoiceAdd} floor={floor} building={building} itemId={itemId} />)
            }
            {
                (page !== null && page !== undefined && page == "office")
                    ? (<OfficeEditModal show={showEditModal} handleOfficeEdit={handleOfficeEdit} itemId={itemId} />)
                    : (<InvoiceEditModal show={showEditModal} handleInvoiceEdit={handleInvoiceEdit} itemId={itemId} invoiceId={invoiceId} />)
            }
        </div>
    );
}
export default Map2;