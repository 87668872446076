
import React, { useState, useEffect, Fragment } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink, useParams, useNavigate } from 'react-router-dom';

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import EditModal from "./EditModal";
import Loader from "../Loader/Loader";
import ConfirmModal from "../ConfirmModal";

import './Invoice.css';

const Invoice = () => {
    // Set Transtaion 
    const { t } = useTranslation();

    // Loading
    const [loading, setLoading] = useState(true);
    const [fromAccount, setFromAccount] = useState({});
    const [toAccount, setToAccount] = useState({});
    const [settings, setSettings] = useState({});
    const navigate = useNavigate();

    // Invoice ID
    const { id } = useParams();

    // Load Data From Server
    const loadData = () => {
        axios.get(`box-activities/invoice/${id}`)
            .then(res => {
                if (res.data.status === 200) {
                    setFromAccount(res.data.from_account);
                    setToAccount(res.data.to_account);
                    setSettings(res.data.settings);
                };
                setLoading(false);
            });
    }

    // Call Print Function
    const callPrintFunction = () => {
        window.print();
    }

    // Handle Edit
    const [itemId, setItemId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEdit = (id, show, type, isUpdated = false) => {
        setShowEditModal(show);
        setItemId(id);
        if (type == "after" && isUpdated == true) {
            loadData();
        }
    }

    // Handle Delete
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDelete = (show, type, isDone = false, $id) => {
        setShowDeleteModal(show);
        setItemId(id);
        if (type == "after" && isDone == true) {
            navigate("/admin/box-activities");
        }
    }

    useEffect(() => {
        loadData();
    }, [id]);

    let invoiceData = '';
    if (loading) {
        return <Loader />
    } else {
        invoiceData =
            <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                <div className="buttons-warpper mb-2">
                    <button className="btn btn-primary mx-1" onClick={callPrintFunction}>
                        <FontAwesomeIcon icon="fa-solid fa-print" className='mx-1' />
                        {t('print')}
                    </button>
                    <button className="btn btn-success mx-1" onClick={() => handleEdit(fromAccount.invoice_id, true, 'before')}>
                        <FontAwesomeIcon icon="fa-solid fa-pencil" className='mx-1' />
                        {t('edit')}
                    </button>
                    <button className="btn btn-danger mx-1" onClick={() => handleDelete(true, "before", false, fromAccount.invoice_id)}>
                        <FontAwesomeIcon icon="fa-solid fa-trash" className='mx-1' />
                        {t('delete')}
                    </button>
                </div>
                <div className="invoice_wrapper">
                    <div className="d-flex justify-content-between align-items-center header">
                        <div className="mb-3 contact_info">
                            <h1>{settings.title}</h1>
                            <h2>
                                <FontAwesomeIcon icon="fa-solid fa-phone" className='mx-1' />
                                {settings.phone}
                            </h2>
                            <h3>
                                <FontAwesomeIcon icon="fa-solid fa-globe" className='mx-1' />
                                {settings.address}
                            </h3>
                        </div>
                        <div className="bill_number">
                            #{toAccount.invoice_id}
                        </div>
                        <div className="mb-3 logo">
                            {
                                (settings.logo !== null && settings.logo !== undefined)
                                    ? (<img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/${settings.logo}`} alt="Logo" className="rounded" />)
                                    : (<img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/logo.png`} alt="Logo" className="rounded" />)
                            }
                        </div>
                    </div>
                    <div className="body">
                        <div className="item mb-2">
                            <b className="d-inline-block">{t('from_account')}</b>
                            {fromAccount.account_name}
                        </div>
                        <div className="item mb-2">
                            <b className="d-inline-block">{t('to_account')}</b>
                            {toAccount.account_name}
                        </div>
                        <div className="item mb-2">
                            <b className="d-inline-block">{t('amount')}</b>
                            {toAccount.iq_debit}
                        </div>
                        <div className="item mb-2">
                            <b className="d-inline-block">{t('description')}</b>
                            {toAccount.description}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center  footer">
                        <div className="item">
                            <b className="d-inline-block">{t('user')}</b>
                            {toAccount.username}
                        </div>
                        <div className="item" dir="ltr">
                            {toAccount.date}
                        </div>
                        <div className="item position-relative">
                            {/* {
                                (settings.logo !== null && settings.logo !== undefined)
                                    ? (<img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/${settings.logo}`} alt="Logo" className="rounded position-absolute" />)
                                    : (<img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/logo.png`} alt="Logo" className="rounded position-absolute" />)
                            } */}
                        </div>
                    </div>
                </div>

                <br />

                <div className="invoice_wrapper">
                    <div className="d-flex justify-content-between align-items-center header">
                        <div className="mb-3 contact_info">
                            <h1>{settings.title}</h1>
                            <h2>
                                <FontAwesomeIcon icon="fa-solid fa-phone" className='mx-1' />
                                {settings.phone}
                            </h2>
                            <h3>
                                <FontAwesomeIcon icon="fa-solid fa-globe" className='mx-1' />
                                {settings.address}
                            </h3>
                        </div>
                        <div className="bill_number">
                            #{toAccount.invoice_id}
                        </div>
                        <div className="mb-3 logo">
                            {
                                (settings.logo !== null && settings.logo !== undefined)
                                    ? (<img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/${settings.logo}`} alt="Logo" className="rounded" />)
                                    : (<img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/logo.png`} alt="Logo" className="rounded" />)
                            }
                        </div>
                    </div>
                    <div className="body">
                        <div className="item mb-2">
                            <b className="d-inline-block">{t('from_account')}</b>
                            {fromAccount.account_name}
                        </div>
                        <div className="item mb-2">
                            <b className="d-inline-block">{t('to_account')}</b>
                            {toAccount.account_name}
                        </div>
                        <div className="item mb-2">
                            <b className="d-inline-block">{t('amount')}</b>
                            {toAccount.iq_debit}
                        </div>
                        <div className="item mb-2">
                            <b className="d-inline-block">{t('description')}</b>
                            {toAccount.description}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center  footer">
                        <div className="item">
                            <b className="d-inline-block">{t('user')}</b>
                            {toAccount.username}
                        </div>
                        <div className="item" dir="ltr">
                            {toAccount.date}
                        </div>
                        <div className="item position-relative">
                            {/* {
                                (settings.logo !== null && settings.logo !== undefined)
                                    ? (<img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/${settings.logo}`} alt="Logo" className="rounded position-absolute" />)
                                    : (<img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/logo.png`} alt="Logo" className="rounded position-absolute" />)
                            } */}
                        </div>
                    </div>
                </div>

            </div>
    }
    return (
        <>
            <Breadcrumb title={t('box_activities')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        {invoiceData}
                    </Col>
                </Row>
            </Container>
            <EditModal show={showEditModal} handleEdit={handleEdit} itemId={itemId} />
            <ConfirmModal show={showDeleteModal} handleModal={handleDelete} apiUrl={`box-activities/delete/${itemId}`} />
        </>
    );
}

export default Invoice;