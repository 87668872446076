import './Topbar.css';

// React Core
import { useState, useEffect } from 'react';
// React Bootstrap
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';

// Other Libraries
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Custom
import ConfirmModal from '../../components/admin/ConfirmModal';

const Topbar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [profileName, setProfileName] = useState('');
    const [profilePic, setProfilePic] = useState('');

    const handleLogout = (show, type, isDone = false) => {
        setShowLogoutModal(show);
        if (type == "after" && isDone == true) {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("auth_name");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_pic");
            localStorage.removeItem("building_id");
            navigate("/");
        }
    }

    useEffect(() => {
        setProfileName(localStorage.getItem('auth_name'));
        setProfilePic(localStorage.getItem('user_pic'));
        setLoading(false);
    }, []);

    let dataTableData = '';
    if (loading) {
        dataTableData = <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                {profileName}
                <img className="rounded-circle mx-2" width="30" height="30" src={`${process.env.REACT_APP_FILE_URL}/public/uploads/profile.png`} alt="Profile" />
            </Dropdown.Toggle>
        </Dropdown>;
    } else {
        dataTableData =
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {profileName}
                    {
                        (profilePic !== null && profilePic !== undefined)
                            ? (<img className="rounded-circle mx-2" width="30" height="30" src={`${process.env.REACT_APP_FILE_URL}/public/uploads/${profilePic}`} alt="Profile" />)
                            : (<img className="rounded-circle mx-2" width="30" height="30" src={`${process.env.REACT_APP_FILE_URL}/public/uploads/profile.png`} alt="profile" />)
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/admin/profile" className='py-2 d-flex'>
                        <FontAwesomeIcon icon="fa-solid fa-user" className='mx-1' />
                        {t('profile')}
                    </Dropdown.Item>

                    {
                        (localStorage.getItem('role') == "admin")
                            ? (<Dropdown.Item as={Link} to="/admin/settings" className='py-2 d-flex'>
                                <FontAwesomeIcon icon="fa-solid fa-cog" className='mx-1' />
                                {t('settings')}
                            </Dropdown.Item>)
                            : ""
                    }
                    <Dropdown.Item className='py-2 d-flex' onClick={() => handleLogout(true, "before")}>
                        <FontAwesomeIcon icon="fa-solid fa-sign-out" className='mx-1' />
                        {t('sign_out')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>;
    }

    return (
        <div className="bg-white pb-2 pt-2 box-shadow1">
            <Container className='profile-dropdown pr-30 pl-30 pt-2 pb-2 d-flex justify-content-end'>
                {dataTableData}
            </Container>
            <ConfirmModal show={showLogoutModal} handleModal={handleLogout} apiUrl="logout" />
        </div>
    );
}

export default Topbar;