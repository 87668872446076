// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Custom Components
import Loader from '../Loader/Loader';
import AccountTypeDropdown from '../SearchableDropdown/SelectDropdown';
import BuildingDropdown from '../SearchableDropdown/SelectDropdown';
import AccountTypeQuickAddModal from '../AccountType/QuickAdd';
import AccountTypeQuickEditModal from '../AccountType/QuickEdit';

const EditModal = ({ show, handleEdit, itemId }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    // Handle account type edit hyper link
    // Handle Account Type  edit form 
    const [accountTypeEditHyperLink, setAccountTypeEditHyperLink] = useState(true);
    const [showAccountTypeEditForm, setShowAccountTypeEditForm] = useState(false);
    const handleAccountTypeEditForm = (type) => {
        setShowAccountTypeEditForm(type);
    }

    // Make updated account type selecetd after updating successfully
    const handleUpdatedAccountType = (item) => {
        setNewAddedAccountType(item);
        setAccountType(item);
    }

    // Account Type Autocomplete Dropdown
    const [accountType, setAccountType] = useState('');
    const handleAccountTypeSelect = (value) => {
        setAccountType(value);
        setAccountTypeEditHyperLink(true);
    }

    // Make the new added account type selecetd after add
    const [newAddedAccountType, setNewAddedAccountType] = useState('');
    const handleNewAddedAccountType = (item) => {
        setNewAddedAccountType(item);
        setAccountType(item);
        setAccountTypeEditHyperLink(true);
    }

    // Handle New Account Type form 
    const [showAccountTypeAddForm, setShowAccountTypeAddForm] = useState(false);
    const handleAccountTypeAddForm = (type) => {
        setShowAccountTypeAddForm(type);
    }

    // Building Autocomplete Dropdown
    const [building, setBuilding] = useState('');
    const handleBuildingSelect = (value) => {
        setBuilding(value);
    }

    // Handle Close Modal
    const handleClose = (isUpdated = false) => {
        setShowModal(false);
        handleEdit(null, false, 'after', isUpdated);
    }

    // Set Form Data
    const [formData, setFormData] = useState({
        name: '',
        account_type: '',
        building: '',
        status: '',
        errors: [],
    });

    // Handle Input
    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    // Handle Submit Form
    const handleFormSubmit = (e) => {
        e.preventDefault();

        let data = JSON.stringify({
            name: formData.name,
            account_type: (typeof accountType === 'object' && !Array.isArray(accountType) && accountType !== null) ? accountType.id : '',
            building: (typeof building === 'object' && !Array.isArray(building) && building !== null) ? building.id : '',
            status: formData.status
        });

        setIsSubmitting(true);

        axios.post(`accounts/update/${itemId}`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose(true);
                    setErrors([]);
                } else if (res.data.status === 400) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(true);
            axios.get(`accounts/edit/${itemId}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFormData({
                            name: res.data.account.name,
                            status: res.data.account.status,
                            building: res.data.account.building_id,
                            account_type: res.data.account.account_type_id,
                            errors: [],
                        });
                        setNewAddedAccountType({ id: res.data.account.account_type_id, name: res.data.account.account_type });
                        setAccountType({ id: res.data.account.account_type_id, name: res.data.account.account_type });
                        setBuilding({ id: res.data.account.building_id, name: res.data.account.building });
                        setLoading(false);
                    }
                })
        }
    }, [show, itemId]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('edit_account')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>

                                        <Col lg={6}>

                                            <AccountTypeQuickAddModal show={showAccountTypeAddForm} handleHideShow={handleAccountTypeAddForm} handleNewAddedItem={handleNewAddedAccountType} />

                                            <AccountTypeQuickEditModal show={showAccountTypeEditForm} handleHideShow={handleAccountTypeEditForm} handleUpdatedItem={handleUpdatedAccountType} currentData={accountType} />

                                            <Form.Label>
                                                <Button variant="primary" type="button" className='p-0 mx-2' onClick={() => handleAccountTypeAddForm(true)}>
                                                    <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                                </Button>
                                                <span>{t('account_type')} *</span>
                                            </Form.Label>

                                            {(() => {
                                                if (accountTypeEditHyperLink) {
                                                    return (
                                                        <a href="#" className='mx-2' onClick={handleAccountTypeEditForm}>{t('edit')}</a>
                                                    );
                                                }
                                            })()}

                                            <AccountTypeDropdown apiUrl={`account-types-autocomplete`} handleSelect={handleAccountTypeSelect} newAddedItem={newAddedAccountType} />
                                            <span className='text-danger'>{errors.account_type}</span>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('name')}  * </Form.Label>
                                                <Form.Control type="text" name="name" onChange={handeInput} value={formData.name} />
                                                <span className='text-danger'>{errors.name}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Label>
                                                <span>{t('building')} *</span>
                                            </Form.Label>
                                            <BuildingDropdown apiUrl={`buildings-autocomplete`} handleSelect={handleBuildingSelect} newAddedItem={building} />
                                            <span className='text-danger'>{errors.building}</span>
                                        </Col>

                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {(() => {
                            if (!loading) {
                                return (
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && (
                                            <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                        )}
                                        <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                        {t('save')}
                                    </Button>
                                )
                            }
                        })()}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );

}

export default EditModal;