// React Core components
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import Loader from "../Loader/Loader";
import Map1 from "./Map1";


const Viewmap = () => {
    const { t } = useTranslation();
    const { url } = useParams();
    const [loading, setLoading] = useState(true);
    const [mapdata, setMapdata] = useState([]);

    const loadData = () => {
        axios.get(`maps/${url}`)
            .then(res => {
                if (res.data.status === 200) {
                    setMapdata(res.data.map);
                    setLoading(false);
                };
            });
    }

    useEffect(() => {
        loadData();
    }, []);

    let relatedMap = '';
    if (loading) {
        return <Loader />
    } else {
        if (url == "map1") {
            relatedMap = <Map1 mapData={mapdata} />
        } else {
            relatedMap =
                <p className="alert alert-info text-center">
                    {t('no_map_found')}
                    <Link to="/admin/maps">
                        <br />
                        {t('back_to_maps')}
                    </Link>
                </p>
        }
    }
    return (
        <>
            <Breadcrumb title={t('maps')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                            {relatedMap}
                        </div>
                    </Col>
                </Row>
            </Container >
        </>
    );
}
export default Viewmap;