
import React from "react";

import { Outlet } from "react-router-dom";

const LoginMasterLayout = () => {
    return (<div className='login'>
            <Outlet />
    </div>
    );
}

export default LoginMasterLayout;