// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Custom Components
import Loader from '../Loader/Loader';

const AddModal = ({ show, handleAdd, building, floor, office }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [buildings, setBuildings] = useState([]);
    const [users, setUsers] = useState([]);
    const [invoices, setInvoices] = useState([]);
    // const [floorLoader, setFloorLoader] = useState(false);
    // const [floors, setFloors] = useState([]);

    const [link, setLink] = useState([]);

    const handleClose = (isCreated = false) => {
        setShowModal(false);
        handleAdd(false, 'after', isCreated);
    }

    const [formData, setFormData] = useState({
        status: '',
        // building: '',
        // floor: '',
        user: '',
        // invoice: '',
        description: '',
        errors: [],
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // if (name == "building") {
        //     setFloorLoader(true);
        //     axios.get(`floors/bybuilding/${value}`)
        //         .then(res => {
        //             if (res.data.status === 200) {
        //                 setFloors(res.data.floors);
        //                 setFloorLoader(false);
        //             }
        //         });
        // }
    }

    const handleLink = (e) => {
        setLink({ link: e.target.files[0] });
    }

    const resetFormData = () => {
        setFormData({
            status: '',
            // building: '',
            // floor: '',
            user: '',
            // invoice: '',
            description: '',
            errors: [],
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let formInputs = new FormData();
        formInputs.append("building", building);
        formInputs.append("floor", floor);
        formInputs.append("office", office);
        formInputs.append("user", localStorage.getItem('user_id'));
        // formInputs.append("invoice", formData.invoice);
        formInputs.append("description", formData.description);
        formInputs.append("status", formData.status);
        formInputs.append("link", link.link);

        setIsSubmitting(true);

        axios.post(`attachments/store`, formInputs, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    resetFormData();
                    handleClose(true);
                    setErrors([]);
                    setLink([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            // setLoading(true);
            setLoading(false);
            // axios.get(`buildings/all`)
            //     .then(res => {
            //         if (res.data.status === 200) {
            //             setBuildings(res.data.buildings);
            //         }
            //     })

            // axios.get(`users/all`)
            //     .then(res => {
            //         if (res.data.status === 200) {
            //             setUsers(res.data.users);
            //         }
            //     })

            //     .then(() => {
            //         axios.get(`invoices/all/${office}`)
            //             .then(res => {
            //                 if (res.data.status === 200) {
            //                     setInvoices(res.data.invoices);
            //                     setLoading(false);
            //                 }
            //             });
            //     });
        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off" encType="multipart/form-data" method='POST'>
                    <Modal.Header closeButton className='bg-light-gray'>
                        <Modal.Title>{t('add_attachment')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('file')} * </Form.Label>
                                                <Form.Control type="file" name="link" onChange={handleLink} />
                                                <span className='text-danger'>{errors.link}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="">---</option>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('description')}  </Form.Label>
                                                <Form.Control as="textarea" rows={3} name="description" onChange={handeInput} value={formData.description ?? ''} />
                                                <span className='text-danger'>{errors.description}</span>
                                            </Form.Group>
                                        </Col>

                                        {/* <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('building')} * </Form.Label>
                                                <Form.Select name="building" onChange={handeInput} value={formData.building}>
                                                    <option value=''>---</option>
                                                    {
                                                        buildings.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <span className='text-danger'>{errors.building}</span>
                                            </Form.Group>
                                        </Col> */}
                                        {/* <Col className="col-lg-6">
                                            <Form.Group className="mb-3 position-relative">
                                                {(() => {
                                                    if (floorLoader) {
                                                        return <Spinner animation="border" className='position-absolute bg-white dropdown-loader' />
                                                    }
                                                })()}
                                                <Form.Label>{t('floor')} * </Form.Label>
                                                <Form.Select name="floor" onChange={handeInput} value={formData.floor}>
                                                    <option value="">---</option>
                                                    {(() => {
                                                        if (floors.length > 0) {
                                                            return (
                                                                floors.map((item) => (
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                ))
                                                            )
                                                        }
                                                    })()}
                                                </Form.Select>
                                                <span className='text-danger'>{errors.floor}</span>
                                            </Form.Group>
                                        </Col> */}

                                        {/* <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('user')} * </Form.Label>
                                                <Form.Select name="user" onChange={handeInput} value={formData.user}>
                                                    <option value=''>---</option>
                                                    {
                                                        users.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <span className='text-danger'>{errors.user}</span>
                                            </Form.Group>
                                        </Col> */}

                                        {/* <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('invoice')} * </Form.Label>
                                                <Form.Select name="invoice" onChange={handeInput} value={formData.invoice}>
                                                    <option value=''>---</option>
                                                    {
                                                        invoices.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.number}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <span className='text-danger'>{errors.invoice}</span>
                                            </Form.Group>
                                        </Col> */}
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-light-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                            )}
                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
export default AddModal;