// React Core Components
import { useState, useEffect, Fragment } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AccountDropdown from '../SearchableDropdown/SelectDropdown';

import '../BoxActivity/Invoice.css';

import EditModal from './EditModal';


// Custom Components
import Loader from '../Loader/Loader';

const AddModal = ({ show, handleAdd }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const [totalDebits, setTotalDebits] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);

    const [items, setItems] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchData, setSearchData] = useState({
        search_term: "",
    });
    const handleSearchInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setSearchData({ ...searchData, [name]: value });
    }

    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        loadboxActivities();
    }

    const loadboxActivities = () => {
        setIsSearching(true);
        axios.get(`search-multi-box-activities?page=1&search_term=${searchData.search_term}`)
            .then(res => {
                if (res.data.status === 200) {
                    setItems(res.data.box_activites.data);
                };
                setIsSearching(false);
            });
    }

    let dataTableData =
        <tr>
            <td className="text-center" colSpan="30">{t('no_records_found')}</td>
        </tr>
    if (isSearching == false) {
        if (items.length > 0) {
            dataTableData =
                items.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <tr>
                                <td>{item.invoice_id}</td>
                                <td>{item.total}</td>
                                <td><p className="ltr m-0 text-end">{item.date}</p></td>
                                <td>{item.description}</td>
                                <td>{item.account_name}</td>
                                <td>{item.iq_debit}</td>
                                <td>{item.iq_credit}</td>
                                <td>
                                    <Button variant="primary" type="button" className='px-2 py-1' onClick={() => handleQuickEditForm(item.invoice_id, true, 'before')}>
                                        <FontAwesomeIcon icon="fa-solid fa-pencil" className='m-0 p-0' />
                                    </Button>
                                </td>
                            </tr>
                        </Fragment>
                    );
                });
        } else {
            dataTableData = (
                <tr>
                    <td className="text-center" colSpan="30">{t('no_records_found')}</td>
                </tr>
            );
        }
    }

    const [currentInvoiceId, setCurrentInvoiceId] = useState("");
    const [showQuickEditForm, setShowQuickEditForm] = useState(false);
    const handleQuickEditForm = (id, show, type, isUpdated = false) => {
        setShowQuickEditForm(show);
        setCurrentInvoiceId(id);
        if (type == "after" && isUpdated == true) {
            loadboxActivities();
        }
    }

    // Set account to empy by default
    const [accountDefaultValue, setAccountDefaultValue] = useState("");

    // Form fields
    const [inputFields, setInputFields] = useState([
        { account: '', debit: 0, credit: 0, note: '' }
    ]);

    // Handle form input change
    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
        setTotalDebits(inputFields.reduce((a, v) => a = a + parseInt(v.debit), 0));
        setTotalCredits(inputFields.reduce((a, v) => a = a + parseInt(v.credit), 0));
    }

    // Add new row
    const addFields = () => {
        let newfield = { account: '', debit: 0, credit: 0, note: '' }
        setInputFields([...inputFields, newfield]);
    }

    // Remove the target row
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data);
    }

    // Handle Account
    const handleAccountFormChange = (index, value) => {
        if (value) {
            inputFields[index].account = value.id;
        } else {
            inputFields[index].account = '';
        }
    }

    const handlePrint = () => {
        window.print();
    }

    // Handle Submit Form
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        axios.post(`multi-box-activities/store`, inputFields)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose(true);
                    setInputFields([{ account: '', debit: 0, credit: 0, note: '' }]);
                } else if (res.data.status === 422) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (res.data.status === 400) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setIsSubmitting(false);
            });
    }

    // Close The Modal
    const handleClose = (isCreated = false) => {
        setShowModal(false);
        handleAdd(false, 'after', isCreated);
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(false);
            setInputFields([{ account: '', debit: 0, credit: 0, note: '' }]);

            setItems([]);
            setSearchData({ search_term: "" });

        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('add_multi_box_activity')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>

                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col lg={12}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '80px', textAlign: "center" }}>{t('no')}</th>
                                                        <th style={{ width: '250px', textAlign: "center" }}>{t('account_name')}</th>
                                                        <th style={{ textAlign: "center" }}>{t('debit')}</th>
                                                        <th style={{ textAlign: "center" }}>{t('credit')}</th>
                                                        <th style={{ textAlign: "center" }}>{t('note')}</th>
                                                        <th style={{ textAlign: "center" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inputFields.map((input, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                                <td>
                                                                    <AccountDropdown apiUrl={`accounts-autocomplete`} handleSelect={event => handleAccountFormChange(index, event)} newAddedItem={accountDefaultValue} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Control type="number" name='debit' value={input.debit} onChange={event => handleFormChange(index, event)} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Control type="number" name='credit' value={input.credit} onChange={event => handleFormChange(index, event)} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Control type="text" name='note' value={input.note} onChange={event => handleFormChange(index, event)} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {(() => {
                                                                        if (index > 0) {
                                                                            return (
                                                                                <Button variant="danger" type="button" className='p-1 hide-in-print' onClick={() => removeFields(index)}>
                                                                                    <FontAwesomeIcon icon="fa-solid fa-times" className='mx-1' />
                                                                                </Button>
                                                                            );
                                                                        }
                                                                    })()}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <Button variant="success" type="button" className='p-1 hide-in-print' onClick={addFields}>
                                                <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        {(() => {
                            if ((totalDebits + totalCredits > 0 && totalDebits == totalCredits)) {
                                return (
                                    <>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            {isSubmitting && (
                                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                            )}
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                            {t('save')}
                                        </Button>
                                        <Button variant="success" type="button" onClick={handlePrint}>
                                            <FontAwesomeIcon icon="fa-solid fa-print" className='mx-1' />
                                            {t('print')}
                                        </Button>
                                    </>
                                );
                            }
                        })()}
                        <Button variant="secondary" onClick={handleClose}>
                            {t('exit')}
                        </Button>
                    </Modal.Footer>
                </Form>
                <div className='bg-white rounded mb-3 p-3'>
                    <Form onSubmit={handleSearchFormSubmit} autoComplete="off">
                        <Row>
                            <Col lg={12}>
                                <hr />
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" name="search_term" onChange={handleSearchInput} value={searchData.search_term} placeholder={t('write_account_name')} />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Button variant="primary" type="submit" disabled={isSearching}>
                                    {isSearching && (
                                        <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                    )}
                                    <FontAwesomeIcon icon="fa-solid fa-search" className='mx-1' />
                                    {t('search')}
                                </Button>
                            </Col>
                            <Col lg={12}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{t('num')}</th>
                                            <th>{t('total')}</th>
                                            <th>{t('date')}</th>
                                            <th>{t('note')}</th>
                                            <th>{t('account_name')}</th>
                                            <th>{t('debit')}</th>
                                            <th>{t('credit')}</th>
                                            <th>{t('actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataTableData}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal >

            <EditModal show={showQuickEditForm} handleEdit={handleQuickEditForm} itemId={currentInvoiceId} />

        </>
    );
}

export default AddModal;