// React Core Components
import React, { useState, useEffect } from "react";

// React-bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

// Other Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import Loader from "../Loader/Loader";

const ListSettings = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [image, setImage] = useState([]);
    const [itemId, setItemId] = useState([]);

    const [formData, setFormData] = useState({
        title: '',
        phone: '',
        email: '',
        errors: [],
    });

    const handleImage = (e) => {
        setImage({ logo: e.target.files[0] });
    }

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let formInputs = new FormData();
        formInputs.append("title", formData.title);
        formInputs.append("phone", formData.phone);
        formInputs.append("email", formData.email);
        formInputs.append("address", formData.address);
        if (image["logo"] !== undefined) {
            formInputs.append("logo", image.logo);
        }

        setIsSubmitting(true);

        axios.post(`settings/update/${itemId}`, formInputs, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                    setImage([]);
                    // setTimeout(function () {
                    //     window.location.reload();
                    // }, 3000);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        document.title = t("settings");
        axios.get(`settings`)
            .then(res => {
                if (res.data.status === 200) {
                    setFormData({
                        title: res.data.setting.title,
                        phone: res.data.setting.phone,
                        email: res.data.setting.email,
                        address: res.data.setting.address,
                        errors: [],
                    });
                    setItemId(res.data.setting.id);
                    setImage([]);
                    setLoading(false);
                }
            })
    }, []);

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Breadcrumb title={t('settings')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                            <Form className='bg-white rounded mb-3 p-3' onSubmit={handleFormSubmit}>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('title')} * </Form.Label>
                                            <Form.Control type="text" name="title" onChange={handeInput} value={formData.title} />
                                            <span className='text-danger'>{errors.title}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('phone')} * </Form.Label>
                                            <Form.Control type="text" name="phone" onChange={handeInput} value={formData.phone} />
                                            <span className='text-danger'>{errors.phone}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('email')} * </Form.Label>
                                            <Form.Control type="text" name="email" onChange={handeInput} value={formData.email} />
                                            <span className='text-danger'>{errors.email}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('address')} * </Form.Label>
                                            <Form.Control type="text" name="address" onChange={handeInput} value={formData.address} />
                                            <span className='text-danger'>{errors.address}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('logo')} * </Form.Label>
                                            <Form.Control type="file" name="logo" onChange={handleImage} />
                                            <span className='text-danger'>{errors.logo}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                            {(() => {
                                                if (!loading) {
                                                    return (
                                                        <Button className="mt-3" variant="primary" type="submit" disabled={isSubmitting}>
                                                            {isSubmitting && (
                                                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                                            )}
                                                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                                            {t('save')}
                                                        </Button>
                                                    )
                                                }
                                            })()}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default ListSettings;