// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Custom Components
import Loader from '../Loader/Loader';

const EditModal = ({ show, handleEdit, itemId }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [buildings, setBuildings] = useState([]);
    // const [floorLoader, setFloorLoader] = useState(false);
    // const [officeLoader, setOfficeLoader] = useState(false);
    // const [floors, setFloors] = useState([]);
    // const [offices, setOffices] = useState([]);

    const handleClose = (isUpdated = false) => {
        setShowModal(false);
        handleEdit(null, false, 'after', isUpdated);
    }

    const [formData, setFormData] = useState({
        name: '',
        qty: 0,
        price: 0,
        total: 0,
        // building: '',
        // floor: '',
        // office: '',
        status: '',
        errors: [],
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
    
        if (name == "qty") {
            let price = parseInt(formData.price);
            setFormData({ ...formData, total: (value*price), qty: value });
        } else if (name == "price") {
            let qty = parseInt(formData.qty);
            setFormData({ ...formData, total: (value*qty), price: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
        // if (name == "building") {
        //     setFloorLoader(true);
        //     axios.get(`floors/bybuilding/${value}`)
        //         .then(res => {
        //             if (res.data.status === 200) {
        //                 setFloors(res.data.floors);
        //                 setFloorLoader(false);
        //             }
        //         });
        // }
        // if (name == "floor") {
        //     setOfficeLoader(true);
        //     axios.get(`offices/byfloor/${value}`)
        //         .then(res => {
        //             if (res.data.status === 200) {
        //                 setOffices(res.data.offices);
        //                 setOfficeLoader(false);
        //             }
        //         });
        // }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            name: formData.name,
            qty: formData.qty,
            price: formData.price,
            total: formData.total,
            status: formData.status,
            // building: formData.building,
            // floor: formData.floor,
            // office: formData.office,
        });
        //console.log(data);
        setIsSubmitting(true);
        axios.post(`assets/update/${itemId}`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose(true);
                    setErrors([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(true);
            axios.get(`assets/edit/${itemId}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFormData({
                            name: res.data.asset.name,
                            qty: res.data.asset.qty,
                            price: res.data.asset.price,
                            total: res.data.asset.total,
                            status: res.data.asset.status,
                            // building: res.data.asset.building_id,
                            // floor: res.data.asset.floor_id,
                            // office: res.data.asset.office_id,
                            errors: [],
                        });
                        // setBuildings(res.data.buildings);
                        // setFloors(res.data.floors);
                        // setOffices(res.data.offices);
                        setLoading(false);
                    }
                })
        }
    }, [show, itemId]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('edit_asset')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('name')} * </Form.Label>
                                                <Form.Control type="text" name="name" onChange={handeInput} value={formData.name} />
                                                <span className='text-danger'>{errors.name}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('qty')}  </Form.Label>
                                                <Form.Control type="number" name="qty" onChange={handeInput} value={formData.qty} />
                                                <span className='text-danger'>{errors.qty}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('price')}  </Form.Label>
                                                <Form.Control type="number" name="price" onChange={handeInput} value={formData.price} />
                                                <span className='text-danger'>{errors.price}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('total')}  </Form.Label>
                                                <Form.Control type="number" name="total" onChange={handeInput} value={formData.total} readOnly />
                                                <span className='text-danger'>{errors.total}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="">---</option>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('building')} * </Form.Label>
                                                <Form.Select name="building" onChange={handeInput} value={formData.building}>
                                                    <option value=''>---</option>
                                                    {
                                                        buildings.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <span className='text-danger'>{errors.building}</span>
                                            </Form.Group>
                                        </Col> */}
                                        {/* <Col className="col-lg-6">
                                            <Form.Group className="mb-3 position-relative">
                                                {(() => {
                                                    if (floorLoader) {
                                                        return <Spinner animation="border" className='position-absolute bg-white dropdown-loader' />
                                                    }
                                                })()}
                                                <Form.Label>{t('floor')} * </Form.Label>
                                                <Form.Select name="floor" onChange={handeInput} value={formData.floor}>
                                                    <option value="">---</option>
                                                    {(() => {
                                                        if (floors.length > 0) {
                                                            return (
                                                                floors.map((item) => (
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                ))
                                                            )
                                                        }
                                                    })()}
                                                </Form.Select>
                                                <span className='text-danger'>{errors.floor}</span>
                                            </Form.Group>
                                        </Col> */}
                                        {/* <Col className="col-lg-6">
                                            <Form.Group className="mb-3 position-relative">
                                                {(() => {
                                                    if (officeLoader) {
                                                        return <Spinner animation="border" className='position-absolute bg-white dropdown-loader' />
                                                    }
                                                })()}
                                                <Form.Label>{t('office')} * </Form.Label>
                                                <Form.Select name="office" onChange={handeInput} value={formData.office}>
                                                    <option value="">---</option>
                                                    {(() => {
                                                        if (offices.length > 0) {
                                                            return (
                                                                offices.map((item) => (
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                ))
                                                            )
                                                        }
                                                    })()}
                                                </Form.Select>
                                                <span className='text-danger'>{errors.office}</span>
                                            </Form.Group>
                                        </Col> */}
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                        {t('close')}
                        </Button>
                        {(() => {
                            if (!loading) {
                                return (
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && (
                                            <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                        )}
                                        <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                        {t('save')}
                                    </Button>
                                )
                            }
                        })()}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );

}

export default EditModal;