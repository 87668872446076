// React Core components
import React, { useState, useEffect } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import Pagination from 'react-bootstrap/Pagination';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, Link, NavLink } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';


// Custom Components
import InvoiceEditModal from "../../Invoice/EditModal";
import Loader from "../../Loader/Loader";

const ListInvoice = ({ id, type, building, floor, loadTotals }) => {
    const { t } = useTranslation();
    const location = useLocation().pathname;
    const targetRoute = location.split("/")[5];

    const [loading, setLoading] = useState(true);
    const [itemList, setItemList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [filtrationData, setFiltrationData] = useState({
        per_page: 10,
        number: '',
    });

    const [showEditModal, setShowEditModal] = useState(false);

    const [itemId, setItemId] = useState(null);
    const [invoiceId, setInvoiceId] = useState(null);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleInvoiceEdit = (office_id, invoice_id, show, type = "before", isUpdated = false) => {
        setShowEditModal(show);
        setItemId(office_id);
        setInvoiceId(invoice_id);
        if (type == "after" && isUpdated == true) {
            loadData();
            loadTotals(true);
        }
    }

    const handeSearchInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFiltrationData({ ...filtrationData, [name]: value });
    }

    const handeShowPerPage = (e) => {
        setFiltrationData({ ...filtrationData, per_page: e.target.value });
    }

    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        loadData();
    }

    const loadData = () => {
        setTableLoading(true);

        let data = filtrationData;

        let from = '';
        if (startDate != "" && startDate != undefined && startDate != null) {
            let fromDate = new Date(startDate);
            from = fromDate.toISOString();
        }

        let to = '';
        if (endDate != "" && endDate != undefined && endDate != null) {
            let toDate = new Date(endDate);
            to = toDate.toISOString();
        }

        axios.get(`offices/invoices?page=1&per_page=${data.per_page}&number=${data.number}&office_id=${id}&from=${from}&to=${to}`)
            .then(res => {
                if (res.data.status === 200) {
                    setItemList(res.data.invoices.data);
                };
                setLoading(false);
                setTableLoading(false);
            });
    }

    useEffect(() => {
        loadData();
    }, [id, type]);

    let dataTableData = '';
    if (loading) {
        return <Loader />
    } else {
        if (itemList.length > 0) {
            dataTableData =
                itemList.map((item, index) => {
                    return (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.user_name}</td>
                            <td>{item.price}</td>
                            <td>{item.discount}</td>
                            <td>{item.paid}</td>
                            <td>{item.remain}</td>
                            <td>{item.start_date}</td>
                            <td>{item.end_date}</td>
                            <td>{item.building_name}</td>
                            <td>{item.floor_name}</td>
                            <td>
                                {(() => {
                                    if (item.status === 1) {
                                        return <Badge bg="success">{t('active')}</Badge>
                                    } else {
                                        return <Badge bg="danger">{t('inactive')}</Badge>
                                    }
                                })()}
                            </td>
                            <td>
                                <NavLink target="_blank" as={Link} to={`/admin/invoices/view/${item.id}`} className='d-block w-100 py-3 px-3 text-decoration-none'>
                                    <FontAwesomeIcon icon="fa-solid fa-eye" className='mx-1' />
                                </NavLink>
                            </td>
                            {/* <td>
                                <Button variant="primary" className='py-1 px-2 btn-light-green' type="button" onClick={() => handleInvoiceEdit(item.office_id, item.id, true)}>
                                    <FontAwesomeIcon icon="fa-solid fa-edit" />
                                </Button>
                            </td> */}
                        </tr>
                    );
                });

        } else {
            dataTableData = (
                <tr>
                    <td className="text-center" colSpan="30">{t('no_records_found')}</td>
                </tr>
            );
        }
    }

    return (
        <>
            <Form className='bg-light-gray rounded mb-3 p-3' onSubmit={handleSearchFormSubmit} autoComplete="off" noValidate>
                <Row>
                    <Col lg={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('invoice_number')}: </Form.Label>
                            <Form.Control type="text" name="number" onChange={handeSearchInput} value={filtrationData.number} />
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('from')} </Form.Label>
                            <div dir="ltr">
                                <DateTimePicker className="form-control" name="start_date" onChange={setStartDate} value={startDate} format="y-MM-dd" required disableClock />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('to')} </Form.Label>
                            <div dir="ltr">
                                <DateTimePicker className="form-control" name="end_date" onChange={setEndDate} value={endDate} format="y-MM-dd" required disableClock />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                            <Button variant="primary" className='mt-4 btn-green' type="submit">
                                <FontAwesomeIcon icon="fa-solid fa-sync-alt" className='mx-1' />
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <div className='datatable-action-area mb-3 d-flex justify-content-between'>
                <div className='d-flex align-items-center'>
                    <Form.Label className='text-nowrap'>{t('show_per_page')}</Form.Label>
                    <Form.Select className='mx-2' name="show_per_page" onChange={handeShowPerPage}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000">{t('all')}</option>
                    </Form.Select>
                </div>
            </div>

            <div className="datatable-action-area mb-3 d-flex justify-content-between flex-column">
                {(() => {
                    if (tableLoading) {
                        return <Loader />
                    } else {
                        return (
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('user')}</th>
                                        <th>{t('price')}</th>
                                        <th>{t('discount')}</th>
                                        <th>{t('paid')}</th>
                                        <th>{t('remain')}</th>
                                        <th>{t('start_date')}</th>
                                        <th>{t('end_date')}</th>
                                        <th>{t('building')}</th>
                                        <th>{t('floor')}</th>
                                        <th>{t('status')}</th>
                                        <th>{t('view')}</th>
                                        {/* <th>{t('actions')}</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataTableData}
                                </tbody>
                            </Table>
                        )
                    }
                })()}
            </div>

            <InvoiceEditModal show={showEditModal} handleInvoiceEdit={handleInvoiceEdit} itemId={itemId} invoiceId={invoiceId} />

        </>
    );
}

export default ListInvoice;