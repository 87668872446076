import './Breadcrumb.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Breadcrumb as Bread } from "react-bootstrap";

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Breadcrumb = ({ title }) => {

    const { t } = useTranslation();

    return (
        <Container className="pr-30 pl-30 mt-3 mb-3" id="breadcrumb_area">
            <Row className="row">
                <Col className="col-md-12">
                    <Bread>
                        <Bread.Item className='default' active as={Link} to="/admin/dashboard">{t('dashboard')}</Bread.Item>
                        {(() => {
                            if (title) {
                                return <Bread.Item active>{title}</Bread.Item>
                            }
                        })()}
                    </Bread>
                </Col>
            </Row>
        </Container>
    );
}

export default Breadcrumb;