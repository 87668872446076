// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Loader from '../Loader/Loader';
import SelectDropdown from '../SearchableDropdown/SelectDropdown';
import AccountQuickAddModal from '../Account/QuickAdd';

const AddModal = ({ show, handleAdd }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [buildings, setBuildings] = useState([]);
    const [pic, setPic] = useState([]);
    const [loading, setLoading] = useState(true);

    // Account Dropdown
    const [account, setAccount] = useState('');
    const handleAccountSelect = (value) => {
        setAccount(value);
    }

    // Make the new added account selecetd after add
    const [newAddedAccount, setNewAddedAccount] = useState('');
    const handleNewAddedAccount = (item) => {
        setNewAddedAccount(item);
        setAccount(item);
    }

    // add new account form 
    const [showAccountAddForm, setShowAccountAddForm] = useState(false);
    const handleAccountAddForm = (type) => {
        setShowAccountAddForm(type);
    }


    const handlePic = (e) => {
        setPic({ pic: e.target.files[0] });
    }

    const handleClose = (isCreated = false) => {
        setShowModal(false);
        handleAdd(false, 'after', isCreated);
        setErrors([]);
        setPic([]);
    }

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        id_card: '',
        company: '',
        address: '',
        status: '',
        password: '',
        building: '',
        account: '',
        errors: [],
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const resetFormData = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            id_card: '',
            company: '',
            address: '',
            status: '',
            password: '',
            building: '',
            account: '',
            errors: [],
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let formInputs = new FormData();
        let userAccount = (typeof account === 'object' && !Array.isArray(account) && account !== null) ? account.id : '';

        formInputs.append("name", formData.name);
        formInputs.append("email", formData.email);
        formInputs.append("phone", formData.phone);
        formInputs.append("id_card", formData.id_card);
        formInputs.append("company", formData.company);
        formInputs.append("address", formData.address);
        formInputs.append("status", formData.status);
        formInputs.append("password", formData.password);
        formInputs.append("building", formData.building);
        formInputs.append("account", userAccount);


        if (pic["pic"] !== undefined) {
            formInputs.append("pic", pic.pic);
        }

        setIsSubmitting(true);

        axios.post(`users/store`, formInputs, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    resetFormData();
                    handleClose(true);
                    setErrors([]);
                    setAccount('');
                    setNewAddedAccount('');
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(true);
            axios.get(`buildings/all?building_id=` + localStorage.getItem('building_id'))
                .then(res => {
                    if (res.data.status === 200) {
                        setBuildings(res.data.buildings);
                        setLoading(false);
                        setAccount('');
                        setNewAddedAccount('');
                    }
                });
        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off" noValidate>
                    <Modal.Header closeButton className='bg-light-gray'>
                        <Modal.Title>{t('add_user')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('name')} * </Form.Label>
                                                <Form.Control type="text" name="name" onChange={handeInput} value={formData.name} />
                                                <span className='text-danger'>{errors.name}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('email')} * </Form.Label>
                                                <Form.Control type="text" name="email" onChange={handeInput} value={formData.email} />
                                                <span className='text-danger'>{errors.email}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('id_card')} </Form.Label>
                                                <Form.Control type="text" name="id_card" onChange={handeInput} value={formData.id_card} />
                                                <span className='text-danger'>{errors.id_card}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('phone')} </Form.Label>
                                                <Form.Control type="text" name="phone" onChange={handeInput} value={formData.phone} />
                                                <span className='text-danger'>{errors.phone}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('company')} </Form.Label>
                                                <Form.Control type="text" name="company" onChange={handeInput} value={formData.company} />
                                                <span className='text-danger'>{errors.company}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('address')} </Form.Label>
                                                <Form.Control type="text" name="address" onChange={handeInput} value={formData.address} />
                                                <span className='text-danger'>{errors.address}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('password')} * </Form.Label>
                                                <Form.Control type="text" name="password" onChange={handeInput} value={formData.password} />
                                                <span className='text-danger'>{errors.password}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="">---</option>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('building')} * </Form.Label>
                                                <Form.Select name="building" onChange={handeInput} value={formData.building}>
                                                    <option value=''>---</option>
                                                    {
                                                        buildings.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <span className='text-danger'>{errors.building}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <AccountQuickAddModal show={showAccountAddForm} handleHideShow={handleAccountAddForm} handleNewAddedItem={handleNewAddedAccount} additionalParams={{ account_type: 3, building: formData.building }} />

                                            <Form.Label>
                                                <Button variant="primary" type="button" className='p-0 mx-2' onClick={() => handleAccountAddForm(true)}>
                                                    <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                                </Button>
                                                <span>{t('account')} *</span>
                                            </Form.Label>
                                            <SelectDropdown apiUrl={`accounts-autocomplete`} handleSelect={handleAccountSelect} newAddedItem={newAddedAccount} additionalParams={{ account_type: 3 }} />
                                            <span className='text-danger'>{errors.account}</span>
                                        </Col>

                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('pic')} </Form.Label>
                                                <Form.Control type="file" name="pic" onChange={handlePic} />
                                                <span className='text-danger'>{errors.pic}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-light-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('close')}
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                            )}
                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
export default AddModal;