// React Core components
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, NavLink } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import Loader from "../Loader/Loader";
import AccountTypeDropdown from '../SearchableDropdown/SelectDropdown';
import BuildingDropdown from '../SearchableDropdown/SelectDropdown';

const ListAccount = () => {

    // Set Transtaion 
    const { t } = useTranslation();

    // Handle Loadings
    const [loading, setLoading] = useState(true);
    const [itemList, setItemList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    // Account Type Autocomplete Dropdown
    const [accountType, setAccountType] = useState('');
    const handleAccountTypeSelect = (value) => {
        setAccountType(value);
    }

    // Building Autocomplete Dropdown
    const [building, setBuilding] = useState('');
    const handleBuildingSelect = (value) => {
        setBuilding(value);
    }

    // Handle Add New Account 
    const [showAddModal, setShowAddModal] = useState(false);
    const handleAdd = (show, type, isCreated = false) => {
        setShowAddModal(show);
        if (type == "after" && isCreated == true) {
            loadData();
        }
    }

    // Handle Exisiting Accoun Edit
    const [showEditModal, setShowEditModal] = useState(false);
    const [itemId, setItemId] = useState(null);
    const handleEdit = (id, show, type, isUpdated = false) => {
        setShowEditModal(show);
        setItemId(id);
        if (type == "after" && isUpdated == true) {
            loadData();
        }
    }

    // Set Filtration  Default Data
    const [filtrationData, setFiltrationData] = useState({
        per_page: 20,
        number: '',
        name: '',
        status: '',
        page: 1,
    });

    // Handle Search Input
    const handeSearchInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFiltrationData({ ...filtrationData, [name]: value, page: 1 });
    }

    // Handle Per Page
    const handeShowPerPage = (e) => {
        setFiltrationData({ ...filtrationData, per_page: e.target.value, page: 1 });
    }

    // Handle Page
    const handlePagination = (e) => {
        setFiltrationData({ ...filtrationData, page: e.target.value });
    }

    // Handle Search Form Submit 
    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        loadData();
    }

    // Set Total Pages and Records
    const [totalPages, setTotalPages] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    // Get Data From Server
    const loadData = () => {

        setTableLoading(true);

        let data = filtrationData;

        let accountTypeID = '';
        if (accountType != "" && accountType != undefined && accountType != null) {
            accountTypeID = accountType.id;
        }

        let buildingID = '';
        if (building != "" && building != undefined && building != null) {
            buildingID = building.id;
        }

        axios.get(`accounts?page=${data.page}&per_page=${data.per_page}&status=${data.status}&name=${data.name}&building=${buildingID}&accountType=${accountTypeID}&building_id=` + localStorage.getItem('building_id'))
            .then(res => {
                if (res.data.status === 200) {
                    setItemList(res.data.accounts.data);
                    setLoading(false);
                    setTableLoading(false);

                    var pagesCounter = [];
                    for (var i = 0; i < res.data.total_pages; i++) {
                        pagesCounter.push({ i });
                    }
                    setTotalPages(pagesCounter);
                    setTotalRecords(res.data.total_records);

                };
            });
    }

    // Load Data For First Time
    useEffect(() => {
        loadData();
    }, [filtrationData.page]);

    // Set Table Data
    let dataTableData = '';
    let tablePagination = '';
    let total_balance = 0;
    if (loading) {
        return <Loader />
    } else {
        if (itemList.length > 0) {
            dataTableData =
                itemList.map((item, index) => {
                    total_balance += (item.debits - item.credits);
                    return (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.account_type}</td>
                            <td>{item.client_name}</td>
                            <td>{item.building}</td>
                            <td>{(item.debits - item.credits)}</td>
                            <td>
                                {(() => {
                                    if (item.status === 1) {
                                        return <Badge bg="success">{t('active')}</Badge>
                                    } else {
                                        return <Badge bg="danger">{t('inactive')}</Badge>
                                    }
                                })()}
                            </td>
                            <td>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {t('actions')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={() => handleEdit(item.id, true, 'before')}>{t('edit')}</Dropdown.Item>

                                        <Dropdown.Item target="_blank" as={Link} to={`/admin/accounts/box-activities/${item.id}`} className='text-decoration-none'>
                                            {t('box_activities')}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>

                                </Dropdown>
                            </td>
                        </tr>
                    );
                });
            tablePagination =
                totalPages.map((item, i) => {
                    return (
                        <option key={i} value={i + 1}>{i + 1}</option>
                    )
                });
        } else {
            dataTableData = (
                <tr>
                    <td className="text-center" colSpan="30">{t('no_records_found')}</td>
                </tr>
            );
        }
    }

    return (
        <>
            <Breadcrumb title={t('accounts')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                            <Form className='bg-dark-gray rounded mb-3 p-3' onSubmit={handleSearchFormSubmit} autoComplete="off">
                                <Row>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('name')} </Form.Label>
                                            <Form.Control type="text" name="name" onChange={handeSearchInput} value={filtrationData.name} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('status')} </Form.Label>
                                            <Form.Select name="status" onChange={handeSearchInput} value={filtrationData.status} >
                                                <option value="">{t('all')}</option>
                                                <option value="1">{t('active')}</option>
                                                <option value="0">{t('inactive')}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Label>{t('account_type')} </Form.Label>
                                        <AccountTypeDropdown apiUrl={`account-types-autocomplete`} handleSelect={handleAccountTypeSelect} newAddedItem="" />
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Label>{t('building')} </Form.Label>
                                        <BuildingDropdown apiUrl={`buildings-autocomplete`} handleSelect={handleBuildingSelect} newAddedItem="" />
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                            <Button variant="primary" className='mt-4' type="submit">
                                                {t('search')}
                                                <FontAwesomeIcon icon="fa-solid fa-search" className='mx-1' />
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>

                            <div className='datatable-action-area mb-3 d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <Form.Label className='text-nowrap'>{t('show_per_page')}</Form.Label>
                                    <Form.Select className='mx-2' name="show_per_page" onChange={handeShowPerPage}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="1000">{t('all')}</option>
                                    </Form.Select>
                                </div>
                                <div className=''>
                                    <Button variant="primary" className='mt-4' onClick={() => handleAdd(true, 'before')}>
                                        {t('add')}
                                        <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                    </Button>
                                </div>
                            </div>

                            <div className="datatable-action-area mb-3 d-flex justify-content-between flex-column">
                                {(() => {
                                    if (tableLoading) {
                                        return <Loader />
                                    } else {
                                        return (
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{t('name')}</th>
                                                        <th>{t('account_type')}</th>
                                                        <th>{t('client')}</th>
                                                        <th>{t('building')}</th>
                                                        <th>{t('balance')}</th>
                                                        <th>{t('status')}</th>
                                                        <th>{t('actions')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataTableData}
                                                </tbody>
                                                {(() => {
                                                    if (totalRecords > 0) {
                                                        return (
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan={4}><b>{t('total')}</b></td>
                                                                    <td colSpan={3}><b>{total_balance}</b></td>
                                                                </tr>
                                                            </tfoot>
                                                        );
                                                    }
                                                })()}
                                            </Table>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (totalRecords > filtrationData.per_page) {
                                        return (
                                            <Form.Group className="m-0 d-flex align-items-center mt-3 pagination-dropdown">
                                                <Form.Label className="mx-2 my-0">{t('page')}: </Form.Label>
                                                <Form.Select name="page" onChange={handlePagination} value={filtrationData.page} >
                                                    {tablePagination}
                                                </Form.Select>
                                            </Form.Group>
                                        );
                                    }
                                })()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <EditModal show={showEditModal} handleEdit={handleEdit} itemId={itemId} />
            <AddModal show={showAddModal} handleAdd={handleAdd} />
        </>
    );
}

export default ListAccount;