
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { ToastContainer } from 'react-toastify';

const AdminMasterLayout = () => {
    return (<div className='d-flex align-items-stretch main-wrapper'>
        <Sidebar />
        <div id="content" className="bg-light-gray w-100 pt-0">
            <Topbar />
            <Outlet />
        </div>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
    </div>
    );
}
export default AdminMasterLayout;