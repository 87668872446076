import './ListDashboard.css';

// React Core components
import React, { useState, useEffect } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';

// Autosearch
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import axios from "axios";

// Other React Libraries
import { useTranslation } from 'react-i18next';

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import Loader from "../Loader/Loader";

const ListDashboard = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const loadData = () => {
        axios.get(`dashboard?building_id=` + localStorage.getItem('building_id'))
            .then(res => {
                if (res.data.status === 200) {
                    setData(res.data.data);
                    setLoading(false);
                };
            });
    }

    useEffect(() => {
        document.title = t('dashboard');
        loadData();
    }, []);

    let dashboardData = '';
    if (loading) {
        return <Loader />
    } else {
        dashboardData =
            <>
                <Row className='mb-3'>
                    <Col>
                        <div className="d-flex justify-content-between bg-white rouned-10 overflow-hidden box-shadow2 dash-info">
                            <div className='px-3 py-3'>
                                <h1>{data.setting.title}</h1>
                                <p><b>{t('phone')}</b>: <span>{data.setting.phone}</span></p>
                                <p><b>{t('email')}</b>: <span>{data.setting.email}</span></p>
                                <p className='m-0'><b>{t('address')}</b>: <span>{data.setting.address}</span></p>
                            </div>
                            <div className='px-3 py-3'>
                                <img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/${data.setting.logo}`} className='img-fluid' alt={data.title} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="row">
                    <Col lg={4}>
                        <div className="bg-white rouned-10 box-shadow2 box1 dash-box mt-3">
                            <div className="datatable-action-area d-flex">
                                <div>
                                    <img src='/images/dbuilding.png' alt={t('building')} />
                                </div>
                                <div>
                                    <h2>{t('total_buildings')}</h2>
                                    <h3 className='m-0 position-relative'>
                                        {data.total_buildings}
                                        <span className='position-absolute'>{t('building')}</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="bg-white rouned-10 box-shadow2 box2 dash-box mt-3">
                            <div className="datatable-action-area d-flex">
                                <div>
                                    <img src='/images/floor.png' alt={t('floor')} />
                                </div>
                                <div>
                                    <h2>{t('total_floors')}</h2>
                                    <h3 className='m-0 position-relative'>
                                        {data.total_floors}
                                        <span className='position-absolute'>{t('floor')}</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="bg-white rouned-10 box-shadow2 box3 dash-box mt-3">
                            <div className="datatable-action-area d-flex">
                                <div>
                                    <img src='/images/office.png' alt={t('office')} />
                                </div>
                                <div>
                                    <h2>{t('total_offices')}</h2>
                                    <h3 className='m-0 position-relative'>
                                        {data.total_offices}
                                        <span className='position-absolute'>{t('office')}</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="mt-4 bg-white rouned-10 dash-row box-shadow2 d-flex align-items-center justify-content-between">
                            <h2 className='m-0'>{t('buildings')}</h2>
                            <div className='d-flex align-items-center justify-content-between active'>
                                <h4 className='m-0'>{t('active')}</h4>
                                <Badge bg="warning" text="dark" className='mx-2'>
                                    <b>{data.total_active_buildings}</b>
                                </Badge>
                            </div>
                            <div className='d-flex align-items-center justify-content-between inactive'>
                                <h4 className='m-0'>{t('inactive')}</h4>
                                <Badge bg="warning" text="dark" className='mx-2'>
                                    <b>{data.total_inactive_buildings}</b>
                                </Badge>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="mt-4 bg-white rouned-10 dash-row box-shadow2 d-flex align-items-center justify-content-between">
                            <h2 className='m-0'>{t('floors')}</h2>
                            <div className='d-flex align-items-center justify-content-between active'>
                                <h4 className='m-0'>{t('active')}</h4>
                                <Badge bg="warning" text="dark" className='mx-2'>
                                    <b>{data.total_active_floors}</b>
                                </Badge>
                            </div>
                            <div className='d-flex align-items-center justify-content-between inactive'>
                                <h4 className='m-0'>{t('inactive')}</h4>
                                <Badge bg="warning" text="dark" className='mx-2'>
                                    <b>{data.total_inactive_floors}</b>
                                </Badge>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12}>
                        <div className="mt-4 bg-white rouned-10 dash-row box-shadow2 d-flex align-items-center justify-content-between">
                            <h2 className='m-0'>{t('offices')}</h2>
                            <div className='d-flex align-items-center justify-content-between active'>
                                <h4 className='m-0'>{t('active')}</h4>
                                <Badge bg="warning" text="dark" className='mx-2'>
                                    <b>{data.total_active_offices}</b>
                                </Badge>
                            </div>
                            <div className='d-flex align-items-center justify-content-between inactive'>
                                <h4 className='m-0'>{t('inactive')}</h4>
                                <Badge bg="warning" text="dark" className='mx-2'>
                                    <b>{data.total_inactive_offices}</b>
                                </Badge>
                            </div>
                        </div>
                    </Col>
                </Row>
            </>
    }

    return (
        <>
            <Breadcrumb title="" />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                {dashboardData}
            </Container>
        </>
    );
}

export default ListDashboard;