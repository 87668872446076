// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal, Table } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Custom Components
import Loader from '../Loader/Loader';
import AccountDropdown from '../SearchableDropdown/SelectDropdown';

const EditModal = ({ show, handleEdit, itemId }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const [totalDebits, setTotalDebits] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);

    // Form fields
    const [inputFields, setInputFields] = useState([
        { accountId: '', accountName: '', debit: 0, credit: 0, note: '' }
    ]);

    // Handle Close Modal
    const handleClose = (isUpdated = false) => {
        setShowModal(false);
        handleEdit(null, false, 'after', isUpdated);
    }

    // Handle form input change
    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
        setTotalDebits(inputFields.reduce((a, v) => a = a + parseInt(v.debit), 0));
        setTotalCredits(inputFields.reduce((a, v) => a = a + parseInt(v.credit), 0));
    }

    // Add new row
    const addFields = () => {
        let newfield = { accountId: '', accountName: '', debit: 0, credit: 0, note: '' }
        setInputFields([...inputFields, newfield]);
    }

    // Remove the target row
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data);
        setTotalDebits(inputFields.reduce((a, v) => a = a + parseInt(v.debit), 0));
        setTotalCredits(inputFields.reduce((a, v) => a = a + parseInt(v.credit), 0));
    }

    // Handle Account
    const handleAccountFormChange = (index, value) => {
        if (value) {
            inputFields[index].accountId = value.id;
            inputFields[index].accountName = value.name;
        } else {
            inputFields[index].accountId = '';
            inputFields[index].accountName = '';
        }
    }

    const handlePrint = () => {
        window.print();
    }

    // Handle Submit Form
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        axios.post(`multi-box-activities/update/${itemId}`, inputFields)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose(true);
                    setInputFields([{ accountId: '', accountName: '', debit: 0, credit: 0, note: '' }]);
                } else if (res.data.status === 422) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (res.data.status === 400) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setIsSubmitting(false);
            });
    }

    // Load Data
    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(true);
            setInputFields({ accountId: '', accountName: '', debit: 0, credit: 0, note: '' });
            axios.get(`multi-box-activities/invoice/${itemId}`)
                .then(res => {
                    if (res.data.status === 200) {
                        let recordsToEdit = [];
                        res.data.box_activities.forEach((row, index) => {
                            let newfield = { accountId: row.account_id, accountName: row.account_name, debit: row.iq_debit, credit: row.iq_credit, note: row.description }
                            recordsToEdit.push(newfield);
                        });
                        setInputFields(recordsToEdit);
                        setLoading(false);
                    }
                })
        }
    }, [show, itemId]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('edit_box_activity')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col lg={12}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '80px', textAlign: "center" }}>{t('no')}</th>
                                                        <th style={{ width: '250px', textAlign: "center" }}>{t('account_name')}</th>
                                                        <th style={{ textAlign: "center" }}>{t('debit')}</th>
                                                        <th style={{ textAlign: "center" }}>{t('credit')}</th>
                                                        <th style={{ textAlign: "center" }}>{t('note')}</th>
                                                        <th style={{ textAlign: "center" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inputFields.map((input, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                                <td>
                                                                    <AccountDropdown apiUrl={`accounts-autocomplete`} handleSelect={event => handleAccountFormChange(index, event)} newAddedItem={{ id: input.accountId, name: input.accountName }} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Control type="number" name='debit' value={input.debit} onChange={event => handleFormChange(index, event)} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Control type="number" name='credit' value={input.credit} onChange={event => handleFormChange(index, event)} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <Form.Control type="text" name='note' value={input.note ?? ''} onChange={event => handleFormChange(index, event)} />
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {(() => {
                                                                        if (index > 0) {
                                                                            return (
                                                                                <Button variant="danger" type="button" className='p-1 hide-in-print' onClick={() => removeFields(index)}>
                                                                                    <FontAwesomeIcon icon="fa-solid fa-times" className='mx-1' />
                                                                                </Button>
                                                                            );
                                                                        }
                                                                    })()}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <Button variant="success" type="button" className='p-1 hide-in-print' onClick={addFields}>
                                                <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        {(() => {
                            if ((totalDebits + totalCredits > 0 && totalDebits == totalCredits)) {
                                return (
                                    <>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            {isSubmitting && (
                                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                            )}
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                            {t('save')}
                                        </Button>
                                        <Button variant="success" type="button" onClick={handlePrint}>
                                            <FontAwesomeIcon icon="fa-solid fa-print" className='mx-1' />
                                            {t('print')}
                                        </Button>
                                    </>
                                );
                            }
                        })()}
                        <Button variant="secondary" onClick={handleClose}>
                            {t('exit')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );

}

export default EditModal;