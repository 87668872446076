// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Custom Components
import Loader from '../Loader/Loader';

const EditModal = ({ show, handleEdit, itemId }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const [image, setImage] = useState([]);

    const handleImage = (e) => {
        setImage({ image: e.target.files[0] });
    }

    const handleClose = (isUpdated = false) => {
        setShowModal(false);
        handleEdit(null, false, 'after', isUpdated);
        setErrors([]);
        setImage([]);
    }

    const [formData, setFormData] = useState({
        name: '',
        code: '',
        address: '',
        status: '',
        errors: [],
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let formInputs = new FormData();
        formInputs.append("name", formData.name);
        formInputs.append("code", formData.code);
        formInputs.append("address", formData.address);
        formInputs.append("status", formData.status);
        if (image["image"] !== undefined) {
            formInputs.append("image", image.image);
        }

        setIsSubmitting(true);

        axios.post(`buildings/update/${itemId}`, formInputs, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                handleClose(true);
                setErrors([]);
                setImage([]);
            } else if (res.data.status === 422) {
                setErrors(res.data.errors);
            }
            setIsSubmitting(false);
        });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(true);
            axios.get(`buildings/edit/${itemId}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFormData({
                            name: res.data.building.name,
                            code: res.data.building.code,
                            address: res.data.building.address,
                            status: res.data.building.status,
                            errors: [],
                        });
                    }

                    setErrors([]);
                    setImage([]);

                    setLoading(false);
                });
        }
    }, [show, itemId]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-light-gray'>
                        <Modal.Title>{t('edit_building')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('name')} * </Form.Label>
                                                <Form.Control type="text" name="name" onChange={handeInput} value={formData.name} />
                                                <span className='text-danger'>{errors.name}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('building_code')} * </Form.Label>
                                                <Form.Control type="text" name="code" onChange={handeInput} value={formData.code} />
                                                <span className='text-danger'>{errors.code}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('address')} * </Form.Label>
                                                <Form.Control type="text" name="address" onChange={handeInput} value={formData.address} />
                                                <span className='text-danger'>{errors.address}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="">---</option>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('image')} * </Form.Label>
                                                <Form.Control type="file" name="image" onChange={handleImage} />
                                                <span className='text-danger'>{errors.image}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-light-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {(() => {
                            if (!loading) {
                                return (
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && (
                                            <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                        )}
                                        <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                        {t('save')}
                                    </Button>
                                )
                            }
                        })()}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );

}

export default EditModal;