// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const QuickAdd = ({ show, handleHideShow, handleNewAddedItem, additionalParams = {} }) => {

    const { t } = useTranslation();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState([]);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        note: '',
        building: '',
        status: 1,
        account_type: '',
        errors: [],
    });

    const resetFormData = () => {
        setFormData({
            first_name: '',
            last_name: '',
            status: 1,
            building: '',
            phone: '',
            address: '',
            note: '',
            errors: [],
        });
    }

    const hideForm = (type) => {
        handleHideShow(type);
    }

    const newAddedItem = (item) => {
        handleNewAddedItem(item);
    }

    const handleInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            first_name: formData.first_name,
            last_name: formData.last_name,
            phone: formData.phone,
            address: formData.address,
            note: formData.note,
            building: formData.building,
            status: formData.status,
            account_type: formData.account_type,
        });

        setIsSubmitted(true);

        axios.post(`clients/store`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                    handleHideShow(false);
                    resetFormData();
                    newAddedItem(res.data.account);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitted(false);
            });
    }

    useEffect(() => {
        resetFormData();
        setFormData({ ...formData, account_type: additionalParams.account_type, building: additionalParams.building });
        setErrors([]);
    }, [show, additionalParams]);

    return (
        <>
            {(() => {
                if (show) {
                    return (
                        <div>
                            <div className='dark-layer'></div>
                            <div className="position-absolute w-100 bg-white rounded px-3 py-4 border-1 float-modal">
                                <Row>

                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('first_name')}  * </Form.Label>
                                            <Form.Control type="text" name="first_name" onChange={handleInput} value={formData.first_name || ""} />
                                            <span className='text-danger'>{errors.first_name}</span>
                                            <p className='text-danger m-0'>{errors.building}</p>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('last_name')}  * </Form.Label>
                                            <Form.Control type="text" name="last_name" onChange={handleInput} value={formData.last_name || ""} />
                                            <span className='text-danger'>{errors.last_name}</span>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('phone')}</Form.Label>
                                            <Form.Control type="text" name="phone" onChange={handleInput} value={formData.phone || ""} />
                                            <span className='text-danger'>{errors.phone}</span>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('address')}</Form.Label>
                                            <Form.Control type="text" name="address" onChange={handleInput} value={formData.address || ""} />
                                            <span className='text-danger'>{errors.address}</span>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('note')}</Form.Label>
                                            <Form.Control type="text" name="note" onChange={handleInput} value={formData.note || ""} />
                                            <span className='text-danger'>{errors.note}</span>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={3}>
                                        <Button variant="primary" type="button" disabled={isSubmitted} onClick={handleFormSubmit}>
                                            {isSubmitted && (
                                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                            )}
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                            {t('save')}
                                        </Button>
                                        <Button variant="danger" type="button" className='mx-1' onClick={() => hideForm(false)}>
                                            <FontAwesomeIcon icon="fa-solid fa-times" className='mx-1' />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
                }
            })()}
        </>
    )
}

export default QuickAdd;