// React Core components
import { React, useEffect } from 'react';
import './App.css';

// Other React Libraries
import { Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import './i18n';
import { useTranslation } from 'react-i18next';

// Custom Components
import ARoues from './routes/admin/ARoutes';
import LRoues from './routes/admin/LRoutes';

// axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

const App = () => {

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <Routes>
      <Route path="/*" element={<LRoues />} />
      <Route path="admin/*" element={<ARoues />} />
    </Routes>
  );
}

export default App;

library.add(fas);
