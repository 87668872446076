// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DateTimePicker from 'react-datetime-picker';

// Custom Components
import Loader from '../Loader/Loader';
import SelectDropdown from '../SearchableDropdown/SelectDropdown';
import ClientQuickAdd from '../Client/QuickAdd';
import ViewInvoice from './ViewInvoice';

const AddModal = ({ show, handleInvoiceAdd, floor, building, itemId }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    // Additional Information
    const [showAdditionalData, setShowAdditionalData] = useState(false);
    const HandleShowAdditionalData = (showOrHide) => {
        setShowAdditionalData(showOrHide)
    }

    // Control Invoice Print
    const [invoiceID, setInvoiceID] = useState(undefined);
    const [showInvoice, setShowInvoice] = useState(false);
    const handleShowInvoice = () => {
        setShowInvoice(true);
        window.print();
    }

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [otherOfficesToRent, setOtherOfficesToRent] = useState([]);

    const [selectedOtherOficesToRent, setselectedOtherOficesToRent] = useState([]);

    // Account Dropdown
    const [account, setAccount] = useState('');
    const handleAccountSelect = (value) => {
        setAccount(value);
    }

    // Make the new added account selecetd after add
    const [newAddedAccount, setNewAddedAccount] = useState('');
    const handleNewAddedAccount = (item) => {
        setNewAddedAccount(item);
        setAccount(item);
    }

    // add new account form 
    const [showAccountAddForm, setShowAccountAddForm] = useState(false);
    const handleAccountAddForm = (type) => {
        setShowAccountAddForm(type);
    }

    const handleClose = (isCreated = false) => {
        setShowModal(false);
        handleInvoiceAdd(itemId, false, 'after', isCreated);
        setLoading(true);
        setInvoiceID(undefined);
        setShowInvoice(false);
    }

    const [formData, setFormData] = useState({
        client: '',
        price: 0,
        discount: 0,
        paid: 0,
        remain: 0,
        start_date: '',
        end_date: '',
        status: 1,
        district: '',
        alley: '',
        house: '',
        company_name: '',
        company_activity: '',
        company_phone_number: '',
        errors: [],
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value, type, checked } = e.target;
        if (name == "price") {
            let price_amount = parseInt(value);
            let discount_percentage = parseInt(formData.discount);
            let paid_amount = parseInt(formData.paid);
            let discount_amount = parseInt(price_amount * discount_percentage / 100);
            let remain_amount = ((price_amount) - (discount_amount + paid_amount));
            setFormData({ ...formData, remain: remain_amount, price: value });
        } else if (name == "paid") {
            let price_amount = parseInt(formData.price);
            let discount_percentage = parseInt(formData.discount);
            let paid_amount = parseInt(value);
            if (paid_amount <= price_amount) {
                let discount_amount = parseInt(price_amount * discount_percentage / 100);
                let remain_amount = ((price_amount) - (discount_amount + paid_amount));
                setFormData({ ...formData, remain: remain_amount, paid: value });
            }
        } else if (name == "discount") {
            let price_amount = parseInt(formData.price);
            let discount_percentage = parseInt(value);
            let paid_amount = parseInt(formData.paid);
            let discount_amount = parseInt(price_amount * discount_percentage / 100);
            let remain_amount = ((price_amount) - (discount_amount + paid_amount));
            setFormData({ ...formData, remain: remain_amount, discount: value });
        } else {
            if (type == "checkbox") {
                if (checked) {
                    setselectedOtherOficesToRent([...selectedOtherOficesToRent, value]);
                } else {
                    setselectedOtherOficesToRent(selectedOtherOficesToRent.filter(item => item !== value));
                }
            } else {
                setFormData({ ...formData, [name]: value });
            }
        }
    }

    const resetFormData = () => {
        setFormData({
            client: '',
            price: 0,
            discount: 0,
            paid: 0,
            remain: 0,
            start_date: '',
            end_date: '',
            status: 1,
            district: '',
            alley: '',
            house: '',
            company_name: '',
            company_activity: '',
            company_phone_number: '',
            errors: [],
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (formData.price > 0) {
            let data = JSON.stringify({
                client: (typeof account === 'object' && !Array.isArray(account) && account !== null) ? account.id : '',
                price: formData.price,
                discount: formData.discount,
                paid: formData.paid,
                remain: formData.remain,
                start_date: startDate,
                end_date: endDate,
                status: formData.status,
                building: building,
                floor: floor,
                office: itemId,
                other_selected_office: selectedOtherOficesToRent,
                district: formData.district,
                alley: formData.alley,
                house: formData.house,
                company_name: formData.company_name,
                company_activity: formData.company_activity,
                company_phone_number: formData.company_phone_number,
            });
            setIsSubmitting(true);
            axios.post(`invoices/store`, data)
                .then(res => {
                    if (res.data.status === 200) {
                        toast.success(res.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        resetFormData();
                        // handleClose(true);
                        setInvoiceID(res.data.invoice_id)
                        setErrors([]);
                        setAccount('');
                        setNewAddedAccount('');
                        setselectedOtherOficesToRent([]);
                        setStartDate('');
                        setEndDate('');
                        setShowInvoice(true);
                    } else if (res.data.status === 400) {
                        toast.warning(res.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setErrors([]);
                    } else if (res.data.status === 422) {
                        setErrors(res.data.errors);
                    }
                    setIsSubmitting(false);
                });
        } else {
            toast.warning(t('print'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    useEffect(() => {
        if (show) {
            axios.get(`offices/load_offices_to_rent/${building}/${floor}/${itemId}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setOtherOfficesToRent(res.data.offices);
                        setShowModal(show);
                        setLoading(false);
                        resetFormData();
                        setErrors([]);
                        setselectedOtherOficesToRent([]);
                        setAccount('');
                        setNewAddedAccount('');

                        // Get Current Date
                        let today = new Date();
                        let dd = String(today.getDate()).padStart(2, '0');
                        let mm = String(today.getMonth() + 1).padStart(2, '0');
                        let yyyy = today.getFullYear();
                        today = yyyy + '/' + mm + '/' + dd;
                        setStartDate(today);

                        setEndDate("");
                    }
                })
        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="xl">

                <Modal.Header closeButton className='bg-light-gray'>
                    <Modal.Title>{t('add_invoice')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white rounded mb-3 p-3'>

                    {(() => {
                        if (showInvoice) {
                            return (
                                <>
                                    <ViewInvoice invoiceID={invoiceID} />
                                    <Modal.Footer className='bg-light-gray'>
                                        <Button variant="secondary" onClick={handleShowInvoice}>
                                            <FontAwesomeIcon icon="fa-solid fa-print" className='mx-1' />
                                            {t('print')}
                                        </Button>
                                        <Button variant="secondary" onClick={e => handleClose(true)}>
                                            {t('close')}
                                        </Button>
                                    </Modal.Footer>
                                </>
                            );
                        } else {
                            return (
                                <Form onSubmit={handleFormSubmit} autoComplete="off" noValidate>
                                    <div className='invoice-form'>
                                        {(() => {
                                            if (loading) {
                                                return <Loader />
                                            } else {
                                                return (
                                                    <Row>

                                                        <Col lg={12}>
                                                            <ClientQuickAdd show={showAccountAddForm} handleHideShow={handleAccountAddForm} handleNewAddedItem={handleNewAddedAccount} additionalParams={{ account_type: 1, building: building }} />

                                                            <Form.Label>
                                                                <Button variant="primary" type="button" className='p-0 mx-2' onClick={() => handleAccountAddForm(true)}>
                                                                    <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                                                </Button>
                                                                <span>{t('client')} *</span>
                                                            </Form.Label>
                                                            <SelectDropdown apiUrl={`accounts-autocomplete`} handleSelect={handleAccountSelect} newAddedItem={newAddedAccount} additionalParams={{ account_type: 1 }} />
                                                            <span className='text-danger'>{errors.client}</span>
                                                        </Col>

                                                        <Col className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('price')}  * </Form.Label>
                                                                <Form.Control type="number" name="price" onChange={handeInput} value={formData.price} />
                                                                <span className='text-danger'>{errors.price}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('discount')}  * </Form.Label>
                                                                <Form.Control type="number" name="discount" onChange={handeInput} value={formData.discount} />
                                                                <span className='text-danger'>{errors.discount}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('paid')}  * </Form.Label>
                                                                <Form.Control type="number" name="paid" onChange={handeInput} value={formData.paid} />
                                                                <span className='text-danger'>{errors.paid}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('remain')}  * </Form.Label>
                                                                <Form.Control type="number" name="remain" onChange={handeInput} value={formData.remain} readOnly />
                                                                <span className='text-danger'>{errors.remain}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('start_date')}  * </Form.Label>
                                                                <div dir='ltr'>
                                                                    <DateTimePicker className="form-control" name="start_date" onChange={setStartDate} value={startDate} format="y-MM-dd" required disableClock />
                                                                    <span className='text-danger'>{errors.start_date}</span>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t('end_date')}  * </Form.Label>
                                                                <div dir='ltr'>
                                                                    <DateTimePicker className="form-control" name="end_date" onChange={setEndDate} value={endDate} format="y-MM-dd" required disableClock />
                                                                    <span className='text-danger'>{errors.end_date}</span>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={12}>
                                                            <p className='bg-dark-gray py-2 px-3 rounded'>
                                                                {t('additional_information')}
                                                                {(() => {
                                                                    if (showAdditionalData) {
                                                                        return (
                                                                            <FontAwesomeIcon icon="fa-solid fa-minus" className='mx-1 main-icon cursor-pointer' onClick={e => HandleShowAdditionalData(false)} />
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1 main-icon cursor-pointer' onClick={e => HandleShowAdditionalData(true)} />
                                                                        );
                                                                    }
                                                                })()}
                                                            </p>
                                                        </Col>
                                                        {(() => {
                                                            if (showAdditionalData) {
                                                                return (
                                                                    <>
                                                                        <Col lg={4}>
                                                                            <Form.Group className="mb-3">
                                                                                <Form.Label>{t('district')}</Form.Label>
                                                                                <Form.Control type="text" name="district" onChange={handeInput} value={formData.district} />
                                                                                <span className='text-danger'>{errors.district}</span>
                                                                            </Form.Group>
                                                                        </Col>

                                                                        <Col lg={4}>
                                                                            <Form.Group className="mb-3">
                                                                                <Form.Label>{t('alley')}</Form.Label>
                                                                                <Form.Control type="text" name="alley" onChange={handeInput} value={formData.alley} />
                                                                                <span className='text-danger'>{errors.alley}</span>
                                                                            </Form.Group>
                                                                        </Col>

                                                                        <Col lg={4}>
                                                                            <Form.Group className="mb-3">
                                                                                <Form.Label>{t('house')}</Form.Label>
                                                                                <Form.Control type="text" name="house" onChange={handeInput} value={formData.house} />
                                                                                <span className='text-danger'>{errors.house}</span>
                                                                            </Form.Group>
                                                                        </Col>

                                                                        <Col lg={4}>
                                                                            <Form.Group className="mb-3">
                                                                                <Form.Label>{t('company_name')}</Form.Label>
                                                                                <Form.Control type="text" name="company_name" onChange={handeInput} value={formData.company_name} />
                                                                                <span className='text-danger'>{errors.company_name}</span>
                                                                            </Form.Group>
                                                                        </Col>

                                                                        <Col lg={4}>
                                                                            <Form.Group className="mb-3">
                                                                                <Form.Label>{t('company_activity')}</Form.Label>
                                                                                <Form.Control type="text" name="company_activity" onChange={handeInput} value={formData.company_activity} />
                                                                                <span className='text-danger'>{errors.company_activity}</span>
                                                                            </Form.Group>
                                                                        </Col>

                                                                        <Col lg={4}>
                                                                            <Form.Group className="mb-3">
                                                                                <Form.Label>{t('company_phone_number')}</Form.Label>
                                                                                <Form.Control type="text" name="company_phone_number" onChange={handeInput} value={formData.company_phone_number} />
                                                                                <span className='text-danger'>{errors.company_phone_number}</span>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </>
                                                                );
                                                            }
                                                        })()}

                                                        <Col className="col-lg-12">
                                                            <p className='m-0'>{t('other_offices_to_rent')}</p>
                                                            <hr className='mt-0' />
                                                            {otherOfficesToRent.map((office) => (
                                                                <div key={office.id} className='w-auto d-inline-block mx-1 mb-2'>
                                                                    <Form.Check
                                                                        reverse
                                                                        label={office.name}
                                                                        type="checkbox"
                                                                        id={office.id}
                                                                        className='bg-dark-gray py-1 ps-2 rounded'
                                                                        name="other_offices" onChange={handeInput} value={office.id}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        })()}
                                    </div>
                                    <Modal.Footer className='bg-light-gray'>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            {isSubmitting && (
                                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                            )}
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                            {t('save')}
                                        </Button>

                                        <Button variant="secondary" onClick={e => handleClose(true)}>
                                            {t('close')}
                                        </Button>

                                    </Modal.Footer>
                                </Form>
                            )
                        }
                    })()}
                </Modal.Body>
            </Modal >
        </>
    );
}

export default AddModal;