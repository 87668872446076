import './Sidebar.css';

// React Core
import { useState, useEffect } from 'react';
// React Boostrap
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// Other Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, NavLink, useLocation } from 'react-router-dom';
// Custom
import ConfirmModal from '../../components/admin/ConfirmModal';

import AddSingleBoxActivityModal from '../../components/admin/BoxActivity/AddModal';

const Sidebar = () => {
    const { t } = useTranslation();
    const location = useLocation().pathname;
    const targetRoute = location.split("/")[2];

    const navigate = useNavigate();
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    // Control Menu One
    const [isMenuOneOpen, setIsMenuOneOpen] = useState(false);
    const HandleIsMenuOneOpen = (isOpen) => {
        setIsMenuOneOpen(isOpen);
        localStorage.setItem("is_menu_one_open", isOpen);
        localStorage.setItem("is_menu_two_open", false);
        setIsMenuTwoOpen(false);
    }

    // Control Menu Two
    const [isMenuTwoOpen, setIsMenuTwoOpen] = useState(false);
    const HandleIsMenuTwoOpen = (isOpen) => {
        setIsMenuTwoOpen(isOpen);
        localStorage.setItem("is_menu_two_open", isOpen);
        localStorage.setItem("is_menu_one_open", false);
        setIsMenuOneOpen(false);
    }

    const closeDropdownMenus = () => {
        setIsMenuTwoOpen(false);
        setIsMenuOneOpen(false);
        localStorage.setItem("is_menu_two_open", false);
        localStorage.setItem("is_menu_one_open", false);
    }

    const handleLogout = (show, type, isDone = false) => {
        setShowLogoutModal(show);
        closeDropdownMenus();
        if (type == "after" && isDone == true) {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("auth_name");
            navigate("/");
        }
    }

    // Handle Add
    const [showAddBoxActivityModal, setShowAddBoxActivityModal] = useState(false);
    const handleAddBoxActivity = (show, type, isCreated = false) => {
        setShowAddBoxActivityModal(show);
    }


    useEffect(() => {

        let menu_one = localStorage.getItem("is_menu_one_open");
        if (menu_one === null || menu_one === 'false') {
            setIsMenuOneOpen(false);
        } else {
            setIsMenuOneOpen(true);
        }

        let menu_two = localStorage.getItem("is_menu_two_open");
        if (menu_two === null || menu_two === 'false') {
            setIsMenuTwoOpen(false);
        } else {
            setIsMenuTwoOpen(true);
        }

    }, []);

    return (
        <Navbar bg="light" expand="lg" className='pt-0 align-items-baseline bg-white box-shadow1 sidebar-menu'>
            <Container className='flex-column align-items-start p-0'>

                <Navbar.Toggle aria-controls="sidebar-menu" className='border-0' />

                <Navbar.Brand href="#" className='px-4 m-0'>
                    {t('login_title')}
                </Navbar.Brand>

                <Navbar.Collapse id="sidebar-menu" className='flex-column w-100'>
                    <nav className="flex-column w-100">

                        <NavLink to="/admin/dashboard" className='d-block w-100 py-3 px-3 text-decoration-none' onClick={closeDropdownMenus}>
                            <FontAwesomeIcon icon="fa-solid fa-house" className='mx-1' />
                            {t('dashboard')}
                        </NavLink>

                        <div className='nav-item-with-submenu'>
                            <span className='menu-item-title py-3 px-3 d-block' onClick={isMenuOneOpen ? e => HandleIsMenuOneOpen(false) : e => HandleIsMenuOneOpen(true)}>
                                <FontAwesomeIcon icon="fa-solid fa-building" className='mx-1 main-icon' />
                                {t('booking_section')}

                                {
                                    isMenuOneOpen ?
                                        <FontAwesomeIcon icon="fa-solid fa-minus" className='mx-1' /> :
                                        <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                }

                            </span>
                            <div className={`sub-menu ${isMenuOneOpen ? 'd-block' : 'd-none'}`}>
                                <NavLink as={Link} to="/admin/buildings" className={`d-block w-100 py-2 px-3 text-decoration-none ${targetRoute == 'buildings' || targetRoute === 'floors' || targetRoute === 'offices' ? 'active' : ''}`}>
                                    {t('buildings')}
                                </NavLink>

                                <NavLink as={Link} to="/admin/invoices" className='d-block w-100 py-2 px-3 text-decoration-none'>
                                    {t('invoices')}
                                </NavLink>

                                <NavLink as={Link} to="/admin/manage-invoices/add/edit" className='d-block w-100 py-2 px-3 text-decoration-none'>
                                    {t('office_report')}
                                </NavLink>

                            </div>
                        </div>

                        <div className='nav-item-with-submenu'>
                            <span className='menu-item-title py-3 px-3 d-block' onClick={isMenuTwoOpen ? e => HandleIsMenuTwoOpen(false) : e => HandleIsMenuTwoOpen(true)}>
                                <FontAwesomeIcon icon="fa-solid fa-coins" className='mx-1 main-icon' />
                                {t('accounting_section')}
                                {
                                    isMenuTwoOpen ?
                                        <FontAwesomeIcon icon="fa-solid fa-minus" className='mx-1' /> :
                                        <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                }
                            </span>
                            <div className={`sub-menu ${isMenuTwoOpen ? 'd-block' : 'd-none'}`}>
                                <NavLink to="/admin/accounts" className='d-block w-100 py-2 px-3 text-decoration-none'>
                                    {t('accounts')}
                                </NavLink>

                                <NavLink to="/admin/box-activities" className='d-block w-100 py-2 px-3 text-decoration-none'>
                                    {t('box_activities')}
                                </NavLink>

                                <span className='d-block w-100 py-2 px-3 text-decoration-none cursor-pointer' onClick={() => handleAddBoxActivity(true, 'before')}>
                                    {t('add_single_box_activity')}
                                </span>

                                <NavLink to="/admin/multi-box-activities" className='d-block w-100 py-2 px-3 text-decoration-none'>
                                    {t('multi_box_activities')}
                                </NavLink>

                                <NavLink as={Link} to="/admin/clients" className='d-block w-100 py-2 px-3 text-decoration-none'>
                                    {t('clients')}
                                </NavLink>
                            </div>
                        </div>

                        <NavLink as={Link} to="/admin/users" className='d-block w-100 py-3 px-3 text-decoration-none' onClick={closeDropdownMenus}>
                            <FontAwesomeIcon icon="fa-solid fa-users" className='mx-1 main-icon' />
                            {t('users')}
                        </NavLink>

                        {
                            (localStorage.getItem('role') == "admin")
                                ? (<NavLink as={Link} to="/admin/settings" className='d-block w-100 py-3 px-3 text-decoration-none' onClick={closeDropdownMenus}>
                                    <FontAwesomeIcon icon="fa-solid fa-cog" className='mx-1 main-icon' />
                                    {t('settings')}
                                </NavLink>)
                                : ""
                        }

                        <Nav.Link href="#" className='d-block w-100 py-2 px-3' onClick={() => handleLogout(true, "before")}>
                            <FontAwesomeIcon icon="fa-solid fa-sign-out" className='mx-1 main-icon' />
                            {t('sign_out')}
                        </Nav.Link>

                    </nav>
                </Navbar.Collapse>
            </Container>

            <ConfirmModal show={showLogoutModal} handleModal={handleLogout} apiUrl="logout" />

            <AddSingleBoxActivityModal show={showAddBoxActivityModal} handleAdd={handleAddBoxActivity} />

        </Navbar>
    );
}

export default Sidebar;