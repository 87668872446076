// React Core components
import React, { useState, useEffect } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import Loader from "../Loader/Loader";

const ListActivity = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [itemList, setItemList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [filtrationData, setFiltrationData] = useState({
        per_page: 10,
        number: '',
        status: 'all'
    });
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [itemId, setItemId] = useState(null);

    const handleAdd = (show, type, isCreated = false) => {
        setShowAddModal(show);
        if (type == "after" && isCreated == true) {
            loadData();
        }
    }

    const handleEdit = (id, show, type, isUpdated = false) => {
        setShowEditModal(show);
        setItemId(id);
        if (type == "after" && isUpdated == true) {
            loadData();
        }
    }

    const handeSearchInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFiltrationData({ ...filtrationData, [name]: value });
    }

    const handeShowPerPage = (e) => {
        setFiltrationData({ ...filtrationData, per_page: e.target.value });
    }

    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        loadData();
    }

    const loadData = () => {
        setTableLoading(true);
        let data = filtrationData;
        axios.get(`activities?page=1&per_page=${data.per_page}&status=${data.status}`)
            .then(res => {
                if (res.data.status === 200) {
                    setItemList(res.data.activities.data);
                    setLoading(false);
                    setTableLoading(false);
                };
            });
    }

    useEffect(() => {
        loadData();
    }, []);

    let dataTableData = '';
    if (loading) {
        return <Loader />
    } else {
        if (itemList.length > 0) {
            dataTableData =
                itemList.map((item, index) => {
                    return (
                        <tr key={item.id}>
                            <td>{item.invoice.number}</td>
                            <td>{item.building.name}</td>
                            <td>{item.floor.name}</td>
                            <td>{item.office.name}</td>
                            <td>{item.reminder}</td>
                            <td>
                                {(() => {
                                    if (item.status === 1) {
                                        return <Badge bg="success">{t('active')}</Badge>
                                    } else {
                                        return <Badge bg="danger">{t('inactive')}</Badge>
                                    }
                                })()}
                            </td>
                            <td>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {t('actions')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={() => handleEdit(item.id, true, 'before')}>{t('edit')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    );
                });
        } else {
            dataTableData = (
                <tr>
                    <td className="text-center" colSpan="30">{t('no_records_found')}</td>
                </tr>
            );
        }
    }

    return (
        <>
            <Breadcrumb title={t('activities')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">

                            <Form className='bg-dark-gray rounded mb-3 p-3' onSubmit={handleSearchFormSubmit} autoComplete="off">
                                <Row>
                                    <Col className="col-lg-4">
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('status')}: </Form.Label>
                                            <Form.Select name="status" onChange={handeSearchInput} value={filtrationData.status} >
                                                <option value="all">{t('all')}</option>
                                                <option value="1">{t('active')}</option>
                                                <option value="0">{t('inactive')}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-lg-4">
                                        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                            <Button variant="primary" className='mt-4' type="submit">
                                                {t('search')}
                                                <FontAwesomeIcon icon="fa-solid fa-search" className='mx-1' />
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>

                            <div className='datatable-action-area mb-3 d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <Form.Label className='text-nowrap'>{t('show_per_page')}</Form.Label>
                                    <Form.Select className='mx-2' name="show_per_page" onChange={handeShowPerPage}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="1000">{t('all')}</option>
                                    </Form.Select>
                                </div>
                                <div className=''>
                                    <Button variant="primary" className='mt-4' onClick={() => handleAdd(true, 'before')}>
                                        {t('add')}
                                        <FontAwesomeIcon icon="fa-solid fa-plus" className='mx-1' />
                                    </Button>
                                </div>
                            </div>

                            <div className="datatable-action-area mb-3 d-flex justify-content-between flex-column">
                                {(() => {
                                    if (tableLoading) {
                                        return <Loader />
                                    } else {
                                        return (
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>{t('invoice')}</th>
                                                        <th>{t('building')}</th>
                                                        <th>{t('floor')}</th>
                                                        <th>{t('office')}</th>
                                                        <th>{t('reminder')}</th>
                                                        <th>{t('status')}</th>
                                                        <th>{t('actions')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataTableData}
                                                </tbody>
                                            </Table>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <EditModal show={showEditModal} handleEdit={handleEdit} itemId={itemId} />
            <AddModal show={showAddModal} handleAdd={handleAdd} />
        </>
    );
}

export default ListActivity;