// React Core components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DateTimePicker from 'react-datetime-picker';

// Custom Components
import Loader from '../Loader/Loader';

const AddModal = ({ show, handleAdd }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [buildings, setBuildings] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [floorLoader, setFloorLoader] = useState(false);
    const [officeLoader, setOfficeLoader] = useState(false);
    const [floors, setFloors] = useState([]);
    const [offices, setOffices] = useState([]);

    const [reminderDate, setReminderDate] = useState();


    const handleClose = (isCreated = false) => {
        setShowModal(false);
        handleAdd(false, 'after', isCreated);
    }

    const [formData, setFormData] = useState({
        building: '',
        floor: '',
        office: '',
        invoice: '',
        reminder: '',
        description: '',
        errors: [],
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name == "building") {
            setFloorLoader(true);
            axios.get(`floors/bybuilding/${value}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFloors(res.data.floors);
                        setFloorLoader(false);
                    }
                });
        }
        if (name == "floor") {
            setOfficeLoader(true);
            axios.get(`offices/byfloor/${value}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setOffices(res.data.offices);
                        setOfficeLoader(false);
                    }
                });
        }
    }

    const resetFormData = () => {
        setFormData({
            building: '',
            floor: '',
            office: '',
            invoice: '',
            reminder: '',
            description: '',
            errors: [],
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            building: formData.building,
            floor: formData.floor,
            office: formData.office,
            invoice: formData.invoice,
            reminder: reminderDate,
            status: formData.status,
            description: formData.description,
        });

        setIsSubmitting(true);

        axios.post(`activities/store`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    resetFormData();
                    handleClose(true);
                    setErrors([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(true);
            axios.get(`buildings/all`)
                .then(res => {
                    if (res.data.status === 200) {
                        setBuildings(res.data.buildings);
                    }
                }).then(() => {
                    axios.get(`invoices/all`)
                        .then(res => {
                            if (res.data.status === 200) {
                                setInvoices(res.data.invoices);
                                setLoading(false);
                            }
                        })
                })
        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('add_activity')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('invoices')} * </Form.Label>
                                                <Form.Select name="invoice" onChange={handeInput} value={formData.invoice}>
                                                    <option value=''>---</option>
                                                    {
                                                        invoices.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.number}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <span className='text-danger'>{errors.invoice}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('building')} * </Form.Label>
                                                <Form.Select name="building" onChange={handeInput} value={formData.building}>
                                                    <option value=''>---</option>
                                                    {
                                                        buildings.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <span className='text-danger'>{errors.building}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3 position-relative">
                                                {(() => {
                                                    if (floorLoader) {
                                                        return <Spinner animation="border" className='position-absolute bg-white dropdown-loader' />
                                                    }
                                                })()}
                                                <Form.Label>{t('floor')} * </Form.Label>
                                                <Form.Select name="floor" onChange={handeInput} value={formData.floor}>
                                                    <option value="">---</option>
                                                    {(() => {
                                                        if (floors.length > 0) {
                                                            return (
                                                                floors.map((item) => (
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                ))
                                                            )
                                                        }
                                                    })()}
                                                </Form.Select>
                                                <span className='text-danger'>{errors.floor}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3 position-relative">
                                                {(() => {
                                                    if (officeLoader) {
                                                        return <Spinner animation="border" className='position-absolute bg-white dropdown-loader' />
                                                    }
                                                })()}
                                                <Form.Label>{t('office')} * </Form.Label>
                                                <Form.Select name="office" onChange={handeInput} value={formData.office}>
                                                    <option value="">---</option>
                                                    {(() => {
                                                        if (offices.length > 0) {
                                                            return (
                                                                offices.map((item) => (
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                ))
                                                            )
                                                        }
                                                    })()}
                                                </Form.Select>
                                                <span className='text-danger'>{errors.office}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('reminder')}  * </Form.Label>
                                                <DateTimePicker className="form-control" name="start_date" onChange={setReminderDate} value={reminderDate} format="y-MM-dd" required disableClock />
                                                <span className='text-danger'>{errors.reminderDate}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="">---</option>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col className="col-lg-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('description')}: </Form.Label>
                                                <Form.Control as="textarea" rows={3} name="description" onChange={handeInput} value={formData.description} />
                                                <span className='text-danger'>{errors.description}</span>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                            )}
                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddModal;