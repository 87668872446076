// React Core components
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';


// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import Loader from "../Loader/Loader";


const Listmap = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [itemList, setItemList] = useState([]);

    const loadData = () => {
        axios.get(`maps`)
            .then(res => {
                if (res.data.status === 200) {
                    setItemList(res.data.maps);
                };
                setLoading(false);
            });
    }

    useEffect(() => {
        loadData();
    }, []);

    let dataTableData = '';
    if (loading) {
        return <Loader />
    } else {
        if (itemList.length > 0) {
            dataTableData =
                itemList.map((item, index) => {
                    return (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.url}</td>
                            <td>
                                <Link className="btn btn-success" to={`/admin/maps/${item.url}`}>{t('view')}</Link>
                            </td>
                        </tr>
                    );
                });

        } else {
            dataTableData = (
                <tr>
                    <td className="text-center" colSpan="30">{t('no_records_found')}</td>
                </tr>
            );
        }
    }
    return (
        <>
            <Breadcrumb title={t('maps')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                            <div className="datatable-action-area mb-3 d-flex justify-content-between flex-column">
                                {(() => {
                                    return (
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>{t('number')}</th>
                                                    <th>{t('url')}</th>
                                                    <th>{t('actions')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataTableData}
                                            </tbody>
                                        </Table>
                                    )
                                })()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Listmap;