import './ListReport.css';

// React Core components
import React, { useState, useEffect } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';


// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useParams, Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Custom Components
import Breadcrumb from "../../../../layouts/Admin/Breadcrumb";
import Loader from "../../Loader/Loader";
import ListUserInvoice from './ListInvoice';
import ListAsset from '../../Asset/ListAsset';
import ListAttachment from '../../Attachment/ListAttachment';

const ListReport = () => {
    const { t } = useTranslation();
    const location = useLocation().pathname;
    const targetRoute = location.split("/")[5];

    const { id, type } = useParams();

    const [loading, setLoading] = useState(true);
    const [officeDetail, setOfficeDetail] = useState([]);

    const [totals, setTotals] = useState({});

    const loadData = () => {
        axios.get(`offices/details/${id}`)
            .then(res => {
                if (res.data.status === 200) {
                    setOfficeDetail(res.data.office);
                };
            }).then(() => {
                loadTotals();
            });
    }

    const loadTotals = (update = true) => {
        if (update) {
            axios.get(`offices/totals/${id}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setTotals(res.data);
                    };
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [type]);

    let userInfoHTML = '';
    if (loading) {
        return <Loader />
    } else {
        userInfoHTML =
            <div>
                <h1 className='text-center profile-name my-2'>{officeDetail.name}</h1>
                <ul className='mt-4 list-group list-group-flush profile-info p-0'>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("building")}</span>
                        <span>{officeDetail.building_name}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("floor")}</span>
                        <span>{officeDetail.floor_name}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("status")}</span>
                        <span>{officeDetail.floor_name}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("room_size")}</span>
                        <span>{officeDetail.room_size}</span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center py-3 px-0 border-0'>
                        <span>{t("company")}</span>
                        <span>{officeDetail.company}</span>
                    </li>
                </ul>
            </div>
    }

    return (
        <>
            <Breadcrumb title={t('reports')} />
            <Container className="pr-30 pl-30 mb-5 user-profile-reports" id="main-container">
                <Row>
                    <Col lg={3}>
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                            {userInfoHTML}
                        </div>
                    </Col>
                    <Col lg={9}>

                        <Row>
                            <Col lg={6}>
                                <div className='pt-3 pl-15 pr-15 pb-15 pt-15 rounded green-box'>
                                    <p className='mb-2 d-flex justify-content-between align-items-center'>
                                        <span>{t('invoice_total_amount')}</span>
                                        <span>{totals.total}</span>
                                    </p>
                                    <p className='mb-2 d-flex justify-content-between align-items-center'>
                                        <span>{t('paid')}</span>
                                        <span>{totals.paid}</span>
                                    </p>
                                    <p className='mb-0 d-flex justify-content-between align-items-center'>
                                        <span>
                                            {t('remain')}
                                        </span>
                                        <span>{totals.remain}</span>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='pt-3 pl-15 pr-15 pb-15 pt-15 rounded green-box'>
                                    <p className='mb-2 d-flex justify-content-between align-items-center'>
                                        <span>{t('total_expenses')}</span>
                                        <span>9000</span>
                                    </p>
                                    <p className='mb-2 d-flex justify-content-between align-items-center'>
                                        <span>{t('assets')}</span>
                                        <span>{totals.total_assets}</span>
                                    </p>
                                    <p className='mb-0 d-flex justify-content-between align-items-center'>
                                        <span>&nbsp;</span>
                                        <span>&nbsp;</span>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mt-3 pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">

                                    <div className="d-flex justify-content-between">
                                        <div className="mt-1 mb-3 filtration-tab">
                                            <Button as={Link} to={`/admin/offices/reports/${id}/invoices`} variant="primary" className={`${targetRoute == 'invoices' ? 'active' : ''}`}>
                                                {t('invoices')}
                                                <FontAwesomeIcon icon="fa-solid fa-file" className="mx-1" />
                                            </Button>
                                            <Button as={Link} to={`/admin/offices/reports/${id}/assets`} variant="primary" className={`${targetRoute == 'assets' ? 'active' : ''}`}>
                                                {t('assets')}
                                                <FontAwesomeIcon icon="fa-solid fa-tools" className="mx-1" />
                                            </Button>
                                            <Button as={Link} to={`/admin/offices/reports/${id}/attachments`} variant="primary" className={`${targetRoute == 'attachments' ? 'active' : ''}`}>
                                                {t('attachments')}
                                                <FontAwesomeIcon icon="fa-solid fa-image" className="mx-1" />
                                            </Button>
                                        </div>
                                        <div>
                                            <Button variant="primary" className="btn-purple" as={Link} to={`/admin/offices/${officeDetail.building_id}/${officeDetail.floor_id}`}>
                                                <FontAwesomeIcon icon="fa-solid fa-angle-left" className='mx-1' />
                                            </Button>
                                        </div>
                                    </div>
                                    {(() => {
                                        switch (type) {
                                            case 'invoices':
                                                return (<ListUserInvoice id={id} building={officeDetail.building_id} floor={officeDetail.floor_id} type={type} loadTotals={loadTotals} />);
                                            case 'assets':
                                                return (<ListAsset id={id} building={officeDetail.building_id} floor={officeDetail.floor_id} type={type} loadTotals={loadTotals} />);
                                            case 'attachments':
                                                return (<ListAttachment id={id} building={officeDetail.building_id} floor={officeDetail.floor_id} type={type} />);
                                            default:
                                                return null
                                        }
                                    })()}
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ListReport;