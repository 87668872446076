
import React, { useState, useEffect, Fragment } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import InvoiceEditModal from "../Invoice/EditModal";
import Loader from "../Loader/Loader";
import ConfirmModal from "../ConfirmModal";

import './ViewInvoice.css';

const ViewInvoice = ({ invoiceID }) => {
    // Set Transtaion 
    const { t } = useTranslation();

    // Loading
    const [loading, setLoading] = useState(true);
    const [mainInvoiceData, setMainInvoiceData] = useState({});
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});
    const [clientAccountName, setClientAccountName] = useState({});
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [additionalData, setAdditionalData] = useState({
        district: '',
        alley: '',
        house: '',
        company_name: '',
        company_activity: '',
        company_phone_number: '',
    });

    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setAdditionalData({ ...additionalData, [name]: value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let data = JSON.stringify({
            district: additionalData.district,
            alley: additionalData.alley,
            house: additionalData.house,
            company_name: additionalData.company_name,
            company_activity: additionalData.company_activity,
            company_phone_number: additionalData.company_phone_number,
        });

        setIsSubmitting(true);

        axios.post(`invoices/updateAdditionalData/${invoice_id}`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setIsSubmitting(false);
            });
    }


    // Invoice ID
    const { id } = useParams();
    let invoice_id = undefined;
    if (id == undefined) {
        invoice_id = invoiceID;
    } else {
        invoice_id = id;
    }

    // Load Data From Server
    const loadData = () => {
        axios.get(`invoices/view/${invoice_id}`)
            .then(res => {
                if (res.data.status === 200) {
                    setMainInvoiceData(res.data.main_invoice_data);
                    setInvoiceDetails(res.data.invoice_details);
                    setSettings(res.data.settings);
                    setClientAccountName(res.data.client_account_name);

                    setAdditionalData({
                        district: res.data.main_invoice_data.district,
                        alley: res.data.main_invoice_data.alley,
                        house: res.data.main_invoice_data.house,
                        company_name: res.data.main_invoice_data.company_name,
                        company_activity: res.data.main_invoice_data.company_activity,
                        company_phone_number: res.data.main_invoice_data.company_phone_number,
                    });
                };
                setLoading(false);
            });
    }

    // Call Print Function
    const callPrintFunction = () => {
        window.print();
    }

    // Handle Edit
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEdit = (itemId, invoiceId, show, type, isUpdated = false) => {
        setShowEditModal(show);
        if (type == "after" && isUpdated == true) {
            loadData();
        }
    }

    // Handle Delete
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDelete = (show, type, isDone = false, $id) => {
        setShowDeleteModal(show);
        if (type == "after" && isDone == true) {
            navigate("/admin/box-activities");
        }
    }

    useEffect(() => {
        loadData();
        document.title = t('view_invoice');
    }, [id]);

    let invoiceData = '';
    let invoiceDetailsData = '';
    if (loading) {
        return <Loader />
    } else {
        invoiceDetailsData = invoiceDetails.map((item, index) => {
            return (
                <tr key={item.id}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{item.price}</td>
                    <td className="text-center">{item.discount}</td>
                    <td className="text-center">{item.paid}</td>
                    <td className="text-center">{item.remain}</td>
                    <td className="text-center">{item.building_name}</td>
                    <td className="text-center">{item.floor_name}</td>
                    <td className="text-center">{item.office_name}</td>
                </tr>
            );
        })
        invoiceData =
            <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">
                <div className="buttons-warpper mb-2">
                    {(() => {
                        if (id != undefined) {
                            return (
                                <>
                                    <button className="btn btn-primary mx-1" onClick={callPrintFunction}>
                                        <FontAwesomeIcon icon="fa-solid fa-print" className='mx-1' />
                                        {t('print')}
                                    </button>
                                    <button className="btn btn-success mx-1" onClick={() => handleEdit(mainInvoiceData.office_id, mainInvoiceData.id, true, 'before')}>
                                        <FontAwesomeIcon icon="fa-solid fa-pencil" className='mx-1' />
                                        {t('edit')}
                                    </button>
                                    <button className="btn btn-danger mx-1" onClick={() => handleDelete(true, "before", false, mainInvoiceData.id)}>
                                        <FontAwesomeIcon icon="fa-solid fa-trash" className='mx-1' />
                                        {t('delete')}
                                    </button>
                                </>
                            )
                        }
                    })()}
                </div>
                <section className="wrapper-invoice rtl">

                    <div className="invoice">

                        <div className="d-flex justify-content-between">
                            <div className="invoice-logo-brand">
                                <img src={`${process.env.REACT_APP_FILE_URL}/public/uploads/${settings.logo}`} alt="Logo" className="rounded" />
                            </div>
                            <div className="invoice-information">
                                <p><b>{t('invoice_number')} #</b> : {mainInvoiceData.id}</p>
                                <p><b>{t('start_date')} </b>: {mainInvoiceData.start_date}</p>
                                <p><b>{t('end_date')}</b> : {mainInvoiceData.end_date}</p>
                            </div>
                        </div>

                        <div className="invoice-head">
                            <div className="head client-info">
                                <p>
                                    <FontAwesomeIcon icon="fa-solid fa-building" className='mx-1' />
                                    {settings.title}
                                </p>
                                <p>
                                    <FontAwesomeIcon icon="fa-solid fa-phone" className='mx-1' />
                                    {settings.phone}
                                </p>
                                <p>
                                    <FontAwesomeIcon icon="fa-solid fa-envelope" className='mx-1' />
                                    {settings.email}
                                </p>
                                <p>
                                    <FontAwesomeIcon icon="fa-solid fa-globe" className='mx-1' />
                                    {settings.address}
                                </p>
                            </div>
                            <div className="head client-data">
                                <p><b>{t('invoice_to')}</b>: {clientAccountName}</p>
                            </div>
                        </div>

                        <div className="invoice-body">
                            <Table className="m-0">
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="text-center">{t('price')}</th>
                                        <th className="text-center">{t('discount')}</th>
                                        <th className="text-center">{t('paid')}</th>
                                        <th className="text-center">{t('remain')}</th>
                                        <th className="text-center">{t('building')}</th>
                                        <th className="text-center">{t('floor')}</th>
                                        <th className="text-center">{t('office')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceDetailsData}
                                </tbody>
                            </Table>
                            <div className="flex-table mt-3">
                                <Form onSubmit={handleFormSubmit} autoComplete="off" className="flex-column ps-5">
                                    <Table className="m-0">
                                        <tbody>
                                            <tr>
                                                <td><b>{t('office_information')}</b></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center additional-invoice-info">
                                                        <div>
                                                            <b>{t('district')} / </b>
                                                            <span className="px-2 print-show">{additionalData.district}</span>
                                                            <Form.Group className="print-hide">
                                                                <Form.Control type="text" name="district" autoComplete="off" value={additionalData.district || ''} onChange={handeInput} />
                                                            </Form.Group>
                                                        </div>
                                                        <div>
                                                            <b>{t('alley')} / </b>
                                                            <span className="px-2 print-show">{additionalData.alley}</span>
                                                            <Form.Group className="print-hide">
                                                                <Form.Control type="text" name="alley" autoComplete="off" value={additionalData.alley || ''} onChange={handeInput} />
                                                            </Form.Group>
                                                        </div>

                                                        <div>
                                                            <b>{t('house')} / </b>
                                                            <span className="px-2 print-show">{additionalData.house}</span>
                                                            <Form.Group className="print-hide">
                                                                <Form.Control type="text" name="house" autoComplete="off" value={additionalData.house || ''} onChange={handeInput} />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{t('company_name')}</b> :
                                                    <span className="px-2 print-show">{additionalData.company_name}</span>
                                                    <Form.Group className="print-hide">
                                                        <Form.Control type="text" name="company_name" autoComplete="off" value={additionalData.company_name || ''} onChange={handeInput} />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{t('company_activity')}</b> :
                                                    <span className="px-2 print-show">{additionalData.company_activity}</span>
                                                    <Form.Group className="print-hide">
                                                        <Form.Control type="text" name="company_activity" autoComplete="off" value={additionalData.company_activity || ''} onChange={handeInput} />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{t('company_phone_number')}</b> :
                                                    <span className="px-2 print-show">{additionalData.company_phone_number}</span>
                                                    <Form.Group className="print-hide">
                                                        <Form.Control type="text" name="company_phone_number" autoComplete="off" value={additionalData.company_phone_number || ''} onChange={handeInput} />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="print-show">
                                                        <b>{t('fingerprint')}</b> :
                                                        <span className="px-2"></span>
                                                    </div>


                                                    <Button variant="primary" type="submit" disabled={isSubmitting} className="print-hide">
                                                        {isSubmitting && (
                                                            <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                                        )}
                                                        <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                                                        {t('save_additional_information')}
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>

                                <div className="flex-column pe-5">
                                    <Table className="m-0">
                                        <tbody>
                                            <tr>
                                                <td><b>{t('total')}</b></td>
                                                <td className="amounts"><b>{mainInvoiceData.price}</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>{t('discount')}</b></td>
                                                <td className="amounts">
                                                    <b>{((mainInvoiceData.price * mainInvoiceData.discount) / 100)}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>{t('paid')}</b></td>
                                                <td className="amounts"><b>{mainInvoiceData.paid}</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>{t('remain')}</b></td>
                                                <td className="amounts"><b>{mainInvoiceData.remain}</b></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
    }
    return (
        <>
            {(() => {
                if (id != undefined) {
                    return (
                        <Breadcrumb title={t('view_invoice')} />
                    )
                }
            })()}

            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        {invoiceData}
                    </Col>
                </Row>
            </Container>
            <InvoiceEditModal show={showEditModal} handleInvoiceEdit={handleEdit} itemId={mainInvoiceData.office_id} invoiceId={mainInvoiceData.id} />
            <ConfirmModal show={showDeleteModal} handleModal={handleDelete} apiUrl={`invoices/delete/${mainInvoiceData.id}`} />
        </>
    );
}

export default ViewInvoice;