// React Core Components
import React, { useState, useEffect, Fragment } from "react";

// React-Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

// Other React Libraries
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimePicker from 'react-datetime-picker';
import { Link, NavLink, useParams } from 'react-router-dom';

// Custom Components
import Breadcrumb from "../../../layouts/Admin/Breadcrumb";
import Loader from "../Loader/Loader";

const ListBoxActivity = () => {

    // Set Transtaion 
    const { t } = useTranslation();

    // Handle Loadings
    const [loading, setLoading] = useState(true);
    const [itemList, setItemList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const { accountID } = useParams();

    // Handle Search with or without balance
    const [searchWithBalance, setSearchWithBalance] = useState(false);
    const handleSearchWithBalanceOnChange = () => {
        setSearchWithBalance(!searchWithBalance);
        localStorage.setItem("is_search_with_balance_checked", !searchWithBalance);
    };

    // Balance
    const [balance, setBalance] = useState(0);

    // From and to dates dropdown
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');


    // Set Filtration  Default Data
    const [filtrationData, setFiltrationData] = useState({
        per_page: 10,
        from: '',
        to: '',
        page: 1,
    });

    // Handle Per Page
    const handeShowPerPage = (e) => {
        setFiltrationData({ ...filtrationData, per_page: e.target.value, page: 1 });
    }

    // Handle Page
    const handlePagination = (e) => {
        setFiltrationData({ ...filtrationData, page: e.target.value });
    }

    // Handle Search Form Submit 
    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        loadData();
    }

    // Set Total Pages and Records
    const [totalPages, setTotalPages] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    // Get Data From Server
    const loadData = () => {
        setTableLoading(true);
        let data = filtrationData;

        let from = '';
        if (startDate != "" && startDate != undefined && startDate != null) {
            let fromDate = new Date(startDate);
            from = fromDate.toISOString();
        }

        let to = '';
        if (endDate != "" && endDate != undefined && endDate != null) {
            let toDate = new Date(endDate);
            to = toDate.toISOString();
        }

        console.log(`box-activities?page=${data.page}&per_page=${data.per_page}&from=${from}&to=${to}&account=${accountID}&building_id=` + localStorage.getItem('building_id'));

        axios.get(`box-activities?page=${data.page}&per_page=${data.per_page}&from=${from}&to=${to}&account=${accountID}&building_id=` + localStorage.getItem('building_id'))
            .then(res => {
                if (res.data.status === 200) {
                    setItemList(res.data.box_activites.data);
                    setLoading(false);
                    setTableLoading(false);
                    setBalance(res.data.balance);

                    var pagesCounter = [];
                    for (var i = 0; i < res.data.total_pages; i++) {
                        pagesCounter.push({ i });
                    }
                    setTotalPages(pagesCounter);
                    setTotalRecords(res.data.total_records);

                };
            });
    }

    // Load Data For First Time
    useEffect(() => {
        loadData();

        // Controll balance check box
        let show_balance = localStorage.getItem("is_search_with_balance_checked");
        if (show_balance === null || show_balance === 'false') {
            setSearchWithBalance(false);
        } else {
            setSearchWithBalance(true);
        }

    }, [filtrationData.page]);

    // Set Table Data
    let dataTableData = '';
    let tablePagination = '';
    let total_debits = 0;
    let total_credits = 0;
    let total_balance = 0;
    if (loading) {
        return <Loader />
    } else {
        if (itemList.length > 0) {
            dataTableData =
                itemList.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <tr>
                                <td rowSpan={item.accounts.length}>
                                    {(() => {
                                        if (item.invoice_type == 1) {
                                            return (
                                                <NavLink target="_blank" as={Link} to={`/admin/activities/innvoice/${item.invoice_id}`} className='d-block w-100 py-3 px-3 text-decoration-none'>
                                                    {item.invoice_id}
                                                </NavLink>
                                            )
                                        } else {
                                            return (
                                                <NavLink target="_blank" as={Link} to={`/admin/invoices/view/${item.invoice_id}`} className='d-block w-100 py-3 px-3 text-decoration-none'>
                                                    {item.invoice_id}
                                                </NavLink>
                                            )
                                        }
                                    })()}
                                </td>
                                <td rowSpan={item.accounts.length}>{item.total}</td>
                                <td rowSpan={item.accounts.length}><p className="ltr m-0 text-end">{item.date}</p></td>
                                <td rowSpan={item.accounts.length}>{item.user_name}</td>
                                <td rowSpan={item.accounts.length}>
                                    {(() => {
                                        total_debits += parseFloat(item.accounts[0].iq_debit);
                                        total_credits += parseFloat(item.accounts[0].iq_credit);
                                        total_balance += (parseFloat(item.accounts[0].iq_debit) - parseFloat(item.accounts[0].iq_credit));
                                        if (item.invoice_type == 1) {
                                            return (
                                                <NavLink target="_blank" as={Link} to={`/admin/activities/innvoice/${item.invoice_id}`} className='d-block w-100 py-3 px-3 text-decoration-none'>
                                                    {item.description}
                                                </NavLink>
                                            )
                                        } else {
                                            return (
                                                <NavLink target="_blank" as={Link} to={`/admin/invoices/view/${item.invoice_id}`} className='d-block w-100 py-3 px-3 text-decoration-none'>
                                                    {item.description}
                                                </NavLink>
                                            )
                                        }
                                    })()}
                                </td>
                                <td>{item.accounts[0].account_name}</td>
                                <td>{item.accounts[0].iq_debit}</td>
                                <td>{item.accounts[0].iq_credit}</td>
                                {(() => {
                                    if (searchWithBalance) {
                                        return (
                                            <td>{(item.accounts[0].total_debits) - (item.accounts[0].total_credits)}</td>
                                        );
                                    }
                                })()}
                            </tr>
                            {(() => {
                                if (item.accounts.length == 2) {
                                    total_debits += parseFloat(item.accounts[1].iq_debit);
                                    total_credits += parseFloat(item.accounts[1].iq_credit);
                                    total_balance += (parseFloat(item.accounts[1].iq_debit) - parseFloat(item.accounts[1].iq_credit));
                                    return (
                                        <tr>
                                            <td>{item.accounts[1].account_name}</td>
                                            <td>{item.accounts[1].iq_debit}</td>
                                            <td>{item.accounts[1].iq_credit}</td>
                                            {(() => {
                                                if (searchWithBalance) {
                                                    return (
                                                        <td>{(item.accounts[1].total_debits) - (item.accounts[1].total_credits)}</td>
                                                    );
                                                }
                                            })()}
                                        </tr>
                                    )
                                } else if (item.accounts.length == 3) {
                                    total_debits += parseFloat(item.accounts[1].iq_debit);
                                    total_credits += parseFloat(item.accounts[1].iq_credit);
                                    total_balance += (parseFloat(item.accounts[1].iq_debit) - parseFloat(item.accounts[1].iq_credit));

                                    total_debits += parseFloat(item.accounts[2].iq_debit);
                                    total_credits += parseFloat(item.accounts[2].iq_credit);
                                    total_balance += (parseFloat(item.accounts[2].iq_debit) - parseFloat(item.accounts[2].iq_credit));

                                    return (
                                        <>
                                            <tr>
                                                <td>{item.accounts[1].account_name}</td>
                                                <td>{item.accounts[1].iq_debit}</td>
                                                <td>{item.accounts[1].iq_credit}</td>
                                                {(() => {
                                                    if (searchWithBalance) {
                                                        return (
                                                            <td>{(item.accounts[1].total_debits) - (item.accounts[1].total_credits)}</td>
                                                        );
                                                    }
                                                })()}
                                            </tr>
                                            <tr>
                                                <td>{item.accounts[2].account_name}</td>
                                                <td>{item.accounts[2].iq_debit}</td>
                                                <td>{item.accounts[2].iq_credit}</td>
                                                {(() => {
                                                    if (searchWithBalance) {
                                                        return (
                                                            <td>{(item.accounts[2].total_debits) - (item.accounts[2].total_credits)}</td>
                                                        );
                                                    }
                                                })()}
                                            </tr>
                                        </>
                                    )
                                } else if (item.accounts.length == 4) {
                                    total_debits += parseFloat(item.accounts[1].iq_debit);
                                    total_credits += parseFloat(item.accounts[1].iq_credit);
                                    total_balance += (parseFloat(item.accounts[1].iq_debit) - parseFloat(item.accounts[1].iq_credit));

                                    total_debits += parseFloat(item.accounts[2].iq_debit);
                                    total_credits += parseFloat(item.accounts[2].iq_credit);
                                    total_balance += (parseFloat(item.accounts[2].iq_debit) - parseFloat(item.accounts[2].iq_credit));

                                    total_debits += parseFloat(item.accounts[3].iq_debit);
                                    total_credits += parseFloat(item.accounts[3].iq_credit);
                                    total_balance += (parseFloat(item.accounts[3].iq_debit) - parseFloat(item.accounts[3].iq_credit));

                                    return (
                                        <>
                                            <tr>
                                                <td>{item.accounts[1].account_name}</td>
                                                <td>{item.accounts[1].iq_debit}</td>
                                                <td>{item.accounts[1].iq_credit}</td>
                                                {(() => {
                                                    if (searchWithBalance) {
                                                        return (
                                                            <td>{(item.accounts[1].total_debits) - (item.accounts[1].total_credits)}</td>
                                                        );
                                                    }
                                                })()}
                                            </tr>
                                            <tr>
                                                <td>{item.accounts[2].account_name}</td>
                                                <td>{item.accounts[2].iq_debit}</td>
                                                <td>{item.accounts[2].iq_credit}</td>
                                                {(() => {
                                                    if (searchWithBalance) {
                                                        return (
                                                            <td>{(item.accounts[2].total_debits) - (item.accounts[2].total_credits)}</td>
                                                        );
                                                    }
                                                })()}
                                            </tr>
                                            <tr>
                                                <td>{item.accounts[3].account_name}</td>
                                                <td>{item.accounts[3].iq_debit}</td>
                                                <td>{item.accounts[3].iq_credit}</td>
                                                {(() => {
                                                    if (searchWithBalance) {
                                                        return (
                                                            <td>{(item.accounts[3].total_debits) - (item.accounts[3].total_credits)}</td>
                                                        );
                                                    }
                                                })()}
                                            </tr>
                                        </>
                                    )
                                }
                            })()}
                        </Fragment>
                    );
                });

            tablePagination =
                totalPages.map((item, i) => {
                    return (
                        <option key={i} value={i + 1}>{i + 1}</option>
                    )
                });

        } else {
            dataTableData = (
                <tr>
                    <td className="text-center" colSpan="30">{t('no_records_found')}</td>
                </tr>
            );
        }
    }

    return (
        <>
            <Breadcrumb title={t('box_activities')} />
            <Container className="pr-30 pl-30 mb-5" id="main-container">
                <Row className="row">
                    <Col className="col-md-12">
                        <div className="pt-3 pl-15 pr-15 pb-15 pt-15 rounded bg-white mh-500 inner-box">

                            <Form className='bg-dark-gray rounded mb-3 p-3' onSubmit={handleSearchFormSubmit} autoComplete="off">
                                <Row>

                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('from_date')} </Form.Label>
                                            <div dir="ltr">
                                                <DateTimePicker className="form-control" name="start_date" onChange={setStartDate} value={startDate} format="y-MM-dd" disableClock />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('to_date')} </Form.Label>
                                            <div dir="ltr">
                                                <DateTimePicker className="form-control" name="end_date" onChange={setEndDate} value={endDate} format="y-MM-dd" disableClock />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                            <Button variant="primary" className='mt-4' type="submit">
                                                {t('search')}
                                                <FontAwesomeIcon icon="fa-solid fa-search" className='mx-1' />
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3 custom-checkbox">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label={t('search_with_or_without_balance')}
                                                checked={searchWithBalance}
                                                name="is_check_box_checked"
                                                onChange={handleSearchWithBalanceOnChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>

                            <div className='datatable-action-area mb-3 d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <Form.Label className='text-nowrap'>{t('show_per_page')}</Form.Label>
                                    <Form.Select className='mx-2' name="show_per_page" onChange={handeShowPerPage}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                        <option value="600">600</option>
                                        <option value="900">900</option>
                                        <option value="1000">1000</option>
                                        <option value="1000">{t('all')}</option>
                                    </Form.Select>
                                </div>
                                {(() => {
                                    if (searchWithBalance) {
                                        return (
                                            <div>
                                                <span className="bg-dark-gray d-inline-block py-2 px-3 rounded">
                                                    <b className="mx-2">{t('total_balance')} :</b>
                                                    <b dir="ltr">{balance}</b>
                                                </span>
                                            </div>
                                        );
                                    }
                                })()}

                            </div>

                            <div className="datatable-action-area mb-3 d-flex justify-content-between flex-column">
                                {(() => {
                                    if (tableLoading) {
                                        return <Loader />
                                    } else {
                                        return (
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{t('num')}</th>
                                                        <th>{t('total')}</th>
                                                        <th>{t('date')}</th>
                                                        <th>{t('user')}</th>
                                                        <th>{t('note')}</th>
                                                        <th>{t('account_name')}</th>
                                                        <th>{t('debit')}</th>
                                                        <th>{t('credit')}</th>
                                                        {(() => {
                                                            if (searchWithBalance) {
                                                                return (
                                                                    <th>{t('balance')}</th>
                                                                );
                                                            }
                                                        })()}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataTableData}
                                                </tbody>
                                                {(() => {
                                                    if (totalRecords > 0) {
                                                        return (
                                                            <tfoot>
                                                                <tr>
                                                                    {(() => {
                                                                        if (searchWithBalance) {
                                                                            return (
                                                                                <>
                                                                                    <td colSpan={6}><b>{t('total')}</b></td>
                                                                                    <td><b>{total_debits}</b></td>
                                                                                    <td><b>{total_credits}</b></td>
                                                                                    <td><b>{total_balance}</b></td>
                                                                                </>
                                                                            );
                                                                        }
                                                                    })()}
                                                                </tr>
                                                            </tfoot>
                                                        );
                                                    }
                                                })()}
                                            </Table>
                                        )
                                    }
                                })()}

                                {(() => {
                                    if (totalRecords > filtrationData.per_page) {
                                        return (
                                            <Form.Group className="m-0 d-flex align-items-center mt-3 pagination-dropdown">
                                                <Form.Label className="mx-2 my-0">{t('page')}: </Form.Label>
                                                <Form.Select name="page" onChange={handlePagination} value={filtrationData.page} >
                                                    {tablePagination}
                                                </Form.Select>
                                            </Form.Group>
                                        );
                                    }
                                })()}

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ListBoxActivity;