// React Core Components
import { useState, useEffect, Fragment } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AccountDropdown from '../SearchableDropdown/SelectDropdown';


// Custom Components
import Loader from '../Loader/Loader';
import EditModal from './EditModal';

const AddModal = ({ show, handleAdd }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    // Search Box Activities
    const [items, setItems] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchData, setSearchData] = useState({
        search_term: "",
    });
    const handleSearchInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setSearchData({ ...searchData, [name]: value });
    }

    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        loadboxActivities();
    }

    const loadboxActivities = () => {
        setIsSearching(true);
        axios.get(`search-box-activities?page=1&search_term=${searchData.search_term}`)
            .then(res => {
                if (res.data.status === 200) {
                    setItems(res.data.box_activites.data);
                };
                setIsSearching(false);
            });
    }

    let dataTableData =
        <tr>
            <td className="text-center" colSpan="30">{t('no_records_found')}</td>
        </tr>
    if (isSearching == false) {
        if (items.length > 0) {
            dataTableData =
                items.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <tr>
                                <td>{item.invoice_id}</td>
                                <td>{item.total}</td>
                                <td><p className="ltr m-0 text-end">{item.date}</p></td>
                                <td>{item.description}</td>
                                <td>{item.account_name}</td>
                                <td>{item.iq_debit}</td>
                                <td>{item.iq_credit}</td>
                                <td>
                                    <Button variant="primary" type="button" className='px-2 py-1' onClick={() => handleQuickEditForm(item.invoice_id, true, 'before')}>
                                        <FontAwesomeIcon icon="fa-solid fa-pencil" className='m-0 p-0' />
                                    </Button>
                                </td>
                            </tr>
                        </Fragment>
                    );
                });
        } else {
            dataTableData = (
                <tr>
                    <td className="text-center" colSpan="30">{t('no_records_found')}</td>
                </tr>
            );
        }
    }

    const [currentInvoiceId, setCurrentInvoiceId] = useState("");
    const [showQuickEditForm, setShowQuickEditForm] = useState(false);
    const handleQuickEditForm = (id, show, type, isUpdated = false) => {
        setShowQuickEditForm(show);
        setCurrentInvoiceId(id);
        if (type == "after" && isUpdated == true) {
            loadboxActivities();
        }
    }

    // From And To Balances
    const [fromAccountBalanceLoader, setFromAccountBalanceLoader] = useState(false);
    const [toAccountBalanceLoader, setToAccountBalanceLoader] = useState(false);

    // Set from account to current user account by default
    const [fromAccountDefaultValue, setFromAccountDefaultValue] = useState("");

    // From Account Dropdown
    const [fromAccount, setFromAccount] = useState('');
    const handleFromAccountSelect = (value) => {
        setFromAccount(value);
        if (value) {
            setFromAccountBalanceLoader(true);
            axios.get(`accounts/balance/${value.id}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFormData({ ...formData, from_balance: res.data.balance });
                        setFromAccountBalanceLoader(false);
                    }
                });
        } else {
            setFormData({ ...formData, from_balance: 0 });
        }
    }

    // To Account Dropdown
    const [toAccount, setToAccount] = useState('');
    const handleToAccountSelect = (value) => {
        setToAccount(value);
        if (value) {
            setToAccountBalanceLoader(true);
            axios.get(`accounts/balance/${value.id}`)
                .then(res => {
                    if (res.data.status === 200) {
                        setFormData({ ...formData, to_balance: res.data.balance });
                        setToAccountBalanceLoader(false);
                    }
                });
        } else {
            setFormData({ ...formData, to_balance: 0 });
        }
    }

    // Close The Modal
    const handleClose = (isCreated = false) => {
        setShowModal(false);
        handleAdd(false, 'after', isCreated);
    }

    // Load My Account to in from account
    const loadMyAccount = () => {
        setFromAccountBalanceLoader(true);
        axios.post(`users/box/${localStorage.getItem('user_id')}`)
            .then(res => {
                if (res.data.status === 200) {
                    setFromAccount({ id: res.data.account.id, name: res.data.account.name });
                    setFromAccountDefaultValue({ id: res.data.account.id, name: res.data.account.name });
                    setFormData({ ...formData, from_balance: res.data.balance });
                    setFromAccountBalanceLoader(false);
                }
            });
    }

    // Modal Data
    const [formData, setFormData] = useState({
        from_balance: 0,
        to_balance: 0,
        exchange_rate: 1,
        usd_amount: 0,
        dinar_amount: 0,
        note: '',
        errors: [],
    });

    // Handle Input
    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    // Rest Data
    const resetFormData = () => {
        setFormData({
            from_balance: 0,
            to_balance: 0,
            exchange_rate: 1,
            usd_amount: 0,
            dinar_amount: 0,
            note: '',
            errors: [],
        });
    }

    // Handle Submit Form
    const handleFormSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            from_account: fromAccount.id,
            to_account: toAccount.id,
            exchange_rate: formData.exchange_rate,
            usd_amount: formData.usd_amount,
            dinar_amount: formData.dinar_amount,
            note: formData.note,
        });
        setIsSubmitting(true);

        axios.post(`box-activities/store`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    resetFormData();
                    setFromAccount("");
                    setToAccount("");
                    handleClose(true);
                    setErrors([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                } else if (res.data.status === 400) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(false);

            resetFormData();
            setFromAccount("");
            setToAccount("");
            setErrors([]);

            setItems([]);
            setSearchData({ search_term: "" });
        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('add_box_activity')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className='d-flex justify-content-between align-items-center'>
                                                <span>{t('from_account')} *</span>
                                                <a href='#' variant="secondary" onClick={loadMyAccount} className='p-0'>
                                                    {t('my_account')}
                                                </a>
                                            </Form.Label>
                                            <AccountDropdown apiUrl={`accounts-autocomplete`} handleSelect={handleFromAccountSelect} newAddedItem={fromAccountDefaultValue} />
                                            <span className='text-danger'>{errors.from_account}</span>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3 position-relative">
                                                <Form.Label>{t('balance')}  * </Form.Label>
                                                <Form.Control type="number" readOnly name="from_balance" onChange={handeInput} value={formData.from_balance} />
                                                {fromAccountBalanceLoader && (
                                                    <Spinner animation="border" className='position-absolute bg-dark-gray dropdown-loader' />
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Label>
                                                <span>{t('to_account')} *</span>
                                            </Form.Label>
                                            <AccountDropdown apiUrl={`accounts-autocomplete`} handleSelect={handleToAccountSelect} newAddedItem="" />
                                            <span className='text-danger'>{errors.to_account}</span>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3 position-relative">
                                                <Form.Label>{t('balance')}  * </Form.Label>
                                                <Form.Control readOnly type="number" name="to_balance" onChange={handeInput} value={formData.to_balance} />
                                                {toAccountBalanceLoader && (
                                                    <Spinner animation="border" className='position-absolute bg-dark-gray dropdown-loader' />
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('exchange_rate')}  * </Form.Label>
                                                <Form.Control type="number" name="exchange_rate" onChange={handeInput} value={formData.exchange_rate} />
                                                <span className='text-danger'>{errors.exchange_rate}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('amount')} ({t('usd')}) * </Form.Label>
                                                <Form.Control type="text" name="usd_amount" onChange={handeInput} value={formData.usd_amount} />
                                                <span className='text-danger'>{errors.usd_amount}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('note')}</Form.Label>
                                                <Form.Control as="textarea" rows={3} name="note" onChange={handeInput} value={formData.note} />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                            )}
                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </Form>
                <div className='bg-white rounded mb-3 p-3'>
                    <Form onSubmit={handleSearchFormSubmit} autoComplete="off">
                        <Row>
                            <Col lg={12}>
                                <hr />
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" name="search_term" onChange={handleSearchInput} value={searchData.search_term} placeholder={t('write_account_name')} />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Button variant="primary" type="submit" disabled={isSearching}>
                                    {isSearching && (
                                        <span className="spinner-grow spinner-grow-sm mr-2"></span>
                                    )}
                                    <FontAwesomeIcon icon="fa-solid fa-search" className='mx-1' />
                                    {t('search')}
                                </Button>
                            </Col>

                            <Col lg={12}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{t('num')}</th>
                                            <th>{t('total')}</th>
                                            <th>{t('date')}</th>
                                            <th>{t('note')}</th>
                                            <th>{t('account_name')}</th>
                                            <th>{t('debit')}</th>
                                            <th>{t('credit')}</th>
                                            <th>{t('actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataTableData}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal >

            <EditModal show={showQuickEditForm} handleEdit={handleQuickEditForm} itemId={currentInvoiceId} />
        </>
    );
}

export default AddModal;