// React Core Components
import { useState, useEffect } from 'react';

// React-Bootstrap components
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Other React Libraries
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Custom Components
import Loader from '../Loader/Loader';

const AddModal = ({ show, handleAdd }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [buildings, setBuildings] = useState([]);

    // Handle Close Modal
    const handleClose = (isCreated = false) => {
        setShowModal(false);
        handleAdd(false, 'after', isCreated);
    }

    // Form Data
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        building: '',
        status: 1,
        phone: '',
        address: '',
        note: '',
        errors: [],
    });

    // Handle Input
    const handeInput = (e) => {
        e.persist();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    // Reset Form Data
    const resetFormData = () => {
        setFormData({
            first_name: '',
            last_name: '',
            status: 1,
            building: '',
            phone: '',
            address: '',
            note: '',
            errors: [],
        });
    }

    // Handle Form Submit
    const handleFormSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            first_name: formData.first_name,
            last_name: formData.last_name,
            status: formData.status,
            building: formData.building,
            phone: formData.phone,
            address: formData.address,
            note: formData.note,
        });

        setIsSubmitting(true);

        axios.post(`clients/store`, data)
            .then(res => {
                if (res.data.status === 200) {
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    resetFormData();
                    handleClose(true);
                    setErrors([]);
                } else if (res.data.status === 400) {
                    toast.warning(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setErrors([]);
                } else if (res.data.status === 422) {
                    setErrors(res.data.errors);
                }
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (show) {
            setShowModal(show);
            setLoading(true);
            setErrors([]);

            axios.get(`buildings/all?building_id=` + localStorage.getItem('building_id'))
                .then(res => {
                    if (res.data.status === 200) {
                        setBuildings(res.data.buildings);
                        setLoading(false);
                    }
                });

        }
    }, [show]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Modal.Header closeButton className='bg-dark-gray'>
                        <Modal.Title>{t('add_client')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-white rounded mb-3 p-3'>
                        {(() => {
                            if (loading) {
                                return <Loader />
                            } else {
                                return (
                                    <Row>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('first_name')}  * </Form.Label>
                                                <Form.Control type="text" name="first_name" onChange={handeInput} value={formData.first_name} />
                                                <span className='text-danger'>{errors.first_name}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('last_name')}  * </Form.Label>
                                                <Form.Control type="text" name="last_name" onChange={handeInput} value={formData.last_name} />
                                                <span className='text-danger'>{errors.last_name}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('phone')}</Form.Label>
                                                <Form.Control type="text" name="phone" onChange={handeInput} value={formData.phone} />
                                                <span className='text-danger'>{errors.phone}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('address')}</Form.Label>
                                                <Form.Control type="text" name="address" onChange={handeInput} value={formData.address} />
                                                <span className='text-danger'>{errors.address}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('status')} * </Form.Label>
                                                <Form.Select name="status" onChange={handeInput} value={formData.status}>
                                                    <option value="1">{t('active')}</option>
                                                    <option value="0">{t('inactive')}</option>
                                                </Form.Select>
                                                <span className='text-danger'>{errors.status}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('building')} * </Form.Label>
                                                <Form.Select name="building" onChange={handeInput} value={formData.building}>
                                                    <option value=''>---</option>
                                                    {
                                                        buildings.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <span className='text-danger'>{errors.building}</span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('note')}</Form.Label>
                                                <Form.Control type="text" name="note" onChange={handeInput} value={formData.note} />
                                                <span className='text-danger'>{errors.note}</span>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                )
                            }
                        })()}
                    </Modal.Body>
                    <Modal.Footer className='bg-dark-gray'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm mr-2"></span>
                            )}
                            <FontAwesomeIcon icon="fa-solid fa-check" className='mx-1' />
                            {t('save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    );
}

export default AddModal;